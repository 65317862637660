import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import ForwardArrow from "patient_app/views/curriculums/partials/ForwardArrow";
import Api from "patient_app/api";

import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { skipCourse } from "patient_app/api/curriculumActions";
import { CURRICULUM_UPDATE_SELECTED_ITEM } from "patient_app/constants/actionTypes";

import { curriculum } from "patient_app/assets/curriculum";

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedItem: (selectedItem) =>
      dispatch({
        type: CURRICULUM_UPDATE_SELECTED_ITEM,
        selectedItem: selectedItem,
      }),
    skipCourse: (curriculumId, exerciseId) =>
      dispatch(skipCourse(curriculumId, exerciseId)),
  };
};

const mapStateToProps = (state) => {
  return {
    selectedItem: state.curriculum.selectedItem,
    skipLoading: state.curriculum.skipLoading,
  };
};

class ItemActions extends React.Component {
  static propTypes = {
    item: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      showComponent: false,
      loading: false,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    //this.setState({showComponent: true})
    setTimeout(() => {
      this.setState({ showComponent: true });
    }, 0);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    event.stopPropagation();
    event.preventDefault();
    //event.nativeEvent.stopImmediatePropagation();
    event.stopImmediatePropagation();

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.updateSelectedItem(null);
    }
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    let { item, skipLoading } = this.props;

    let { showComponent, loading } = this.state;

    const topButton = this.getTopButton(item);
    const bottomButton = this.getBottomButton(item);

    return (
      <div
        className={cn(
          "item-actions",
          showComponent && "selected",
          skipLoading && "loading",
          loading && "loading"
        )}
        ref={this.setWrapperRef}
      >
        <div className={cn("actions", loading && "loading")}>
          {topButton}

          {bottomButton}
        </div>
      </div>
    );
  }

  getTopButton = (item) => {
    let button;
    let { user, skipLoading } = this.props;

    if (item.is_special_delivery) return;

    const reviewPath = gon.admin_bypass
      ? ""
      : `/users/${user.id}/review_exercise?exercise=${item.exercise.id}&item_id=${item.id}`;

    if (!item.required && item.status !== "SKIPPED") {
      button = (
        <button
          className="skip"
          onClick={() => this.handleSkipCourse(item.exercise.id)}
        >
          {skipLoading ? (
            <div>
              <span>Loading...</span>
            </div>
          ) : (
            <div>
              <span>Skip</span>
              <img className="icon" src={icons.flash} alt="" />
            </div>
          )}
        </button>
      );
    }

    if (item.status === "COMPLETED" && item.response) {
      button = (
        <button
          className="skip"
          onClick={() => {
            window.location.href = reviewPath;
          }}
        >
          <span>Review</span>
          <img className="icon" src={curriculum.list} alt="" />
        </button>
      );
    }

    return button;
  };

  getBottomButton(item) {
    let text;
    switch (item.status) {
      case "COMPLETED":
        text = "Retake Course";
        break;
      case "IN PROGRESS":
        text = "Continue";
        break;
      default:
        text = "Start Course";
        break;
    }

    //special case, skipped but still started
    if (
      text === "Start Course" &&
      item.response !== null &&
      !item.is_special_delivery
    ) {
      text = "Continue";
    }

    return (
      <button
        className="take custom-button"
        onClick={(e) => this.takeCourse(item)}
      >
        <span>{text}</span>
        <ForwardArrow />
      </button>
    );
  }

  takeCourse = async (item) => {
    let id = item.exercise.id;
    this.setState({ loading: true });
    let path = `/exercise_responses/create?exercise=${id}`;
    if (item.is_special_delivery) {
      path += "&item_id=special_delivery";
      path += "&special_delivery=true";
    }
    else {
      path += `&item_id=${item.id}`;
    }
    const data = {
      url: path,
      data: {
        method: "POST",
      },
    };

    try {
      const res = await Api.makeRequest(data);
      if (res.path) {
        window.location.href = res.path;
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleSkipCourse = (exerciseId) => {
    if (gon.admin_bypass) return;

    const curriculumId = this.props.match.params.id
      ? this.props.match.params.id
      : "default";
    this.props.skipCourse(curriculumId, exerciseId);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ItemActions)
);
