import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import FormErrors from "patient_app/components/utils/FormErrors";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  Link,
} from "react-router-dom";
import { connect } from "react-redux";
import { fetchUser } from "patient_app/api/commonActions";
import { fetchOutcome } from "patient_app/api/onboardingActions";
import {
  NEW_FORM_ERRORS,
  TOGGLE_LOADING,
  ONBOARDING_UPDATE_PROGRESS,
} from "patient_app/constants/actionTypes";

import MainLayout from "patient_app/components/layouts/MainLayout";

import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import SubmitButton from "patient_app/components/buttons/SubmitButton";
import OnboardingProgressBar from "patient_app/components/utils/OnboardingProgressBar";

import commonAssets from "patient_app/assets";
import onboardingHelpers from "patient_app/helpers/onboardingHelpers";

import layout from "patient_app/stylesheets/onboarding/layout.js";
import programDescriptionStyles from "patient_app/stylesheets/onboarding/programDescriptionStyles.js";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(fetchUser()),
    fetchOutcome: (id) => dispatch(fetchOutcome(id)),
    toggleLoading: () => dispatch({ type: TOGGLE_LOADING, loading: true }),
    updateProgress: (progress) =>
      dispatch({ type: ONBOARDING_UPDATE_PROGRESS, progress: progress }),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.common.user,
    profile: state.common.profile,
    loading: state.onboarding.loading,
    questions: state.onboarding.enterpriseQuestions,
  };
};

class Outcome extends React.Component {
  componentDidMount = () => {
    if (!this.props.user) {
      this.props.fetchUser();
    }

    let id = this.props.match.params.id;
    this.props.fetchOutcome(id);

    document.title = "Questionnaire Results | Workit Health";
    window.scrollTo(0, 0);

    const progress = onboardingHelpers.calculateProgress(
      this.props.location.pathname
    );
    this.props.updateProgress(progress);
  };

  getQuestionKey = () => {
    let { questions } = this.props;
    if (!questions) return;

    let id = this.props.match.params.id;

    for (var q in questions) {
      if (questions[q].id.toString() === id) return q;
    }
  };

  render() {
    let { user, profile, loading, questions } = this.props;

    let key = this.getQuestionKey();
    let question = questions[key];
    let questionText = question ? question.text.split("<br/>") : null;

    return (
      <MainLayout>
        <ContentMain halfBleed={true}>
          {question && (
            <ContentBox className="onboarding">
              <OnboardingProgressBar />

              <div className="inputs">
                <h1>Welcome to Workit</h1>

                <div className="center-horizontal">
                  {profile && profile.coach && (
                    <div className="center-horizontal">
                      <img
                        src={profile.coach.img}
                        className="coach-img"
                        alt={`A headshot of ${profile.coach.name}`}
                      />
                      <p className="description">
                        Meet Your Coach: {profile.coach.name}
                      </p>
                    </div>
                  )}

                  <div className="program-info">
                    {this.renderLines(questionText)}
                  </div>
                </div>

                <div className="align-bottom fill">
                  <SubmitButton
                    text={"Next"}
                    loading={loading}
                    onSubmit={this.handleSubmit}
                  />
                </div>
              </div>

              <style jsx>{layout}</style>
              <style jsx>{programDescriptionStyles}</style>
            </ContentBox>
          )}
        </ContentMain>
      </MainLayout>
    );
  }

  renderLines = (text) => {
    return text.map((line, i) => {
      return <p key={i}>{line}</p>;
    });
  };

  handleSubmit = (e) => {
    let { user } = this.props;
    if (e) e.preventDefault();

    this.props.toggleLoading();

    // if ((user.tier === 1)) {
    //   this.props.history.push('/onboarding/conclusion');
    // }
    // else {
    //   this.props.history.push('/onboarding/schedule');
    // }
    this.props.history.push("/onboarding/address");
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Outcome)
);
