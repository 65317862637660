import axios from "axios";
import qs from "qs";

class ApiClass {
  constructor() {
    let host = "https://app.workithealth.com";
    if (
      window.location.hostname &&
      window.location.hostname.startsWith("staging")
    ) {
      host = "https://staging.workithealth.com";
    } else if (
      window.location.hostname &&
      window.location.hostname.startsWith("dev")
    ) {
      host = "https://dev.workithealth.com";
    } else if (
      window.location.hostname &&
      window.location.hostname.startsWith("demophi")
    ) {
      host = "https://demophi.workithealth.com";
    } else if (
      (window.location.hostname &&
        window.location.hostname.startsWith("local")) ||
      (window.location.hostname && window.location.hostname.includes("ngrok"))
    ) {
      host = `http://localhost:3000`;
    }

    this.url = host;
    this.base = axios.create({
      baseURL: host,
      timeout: 15000,
      headers: this.getHeaders(),
    });
  }

  makeRequest = async ({ url, data, path = "" }) => {
    // prepend the optional 'path' string to use controllers in the '/api/v1' directory
    try {
      const response = await this.base({
        method: data.method,
        url: path + url,
        data: data.body ? this.serialize(data.body) : {},
      });

      if (response.status >= 300) {
        return response;
      }

      return response.data;
    } catch (e) {
      if (e.message.includes("401")) {
        return { unauthorized: true };
      }
    }
  };

  makeExternalRequest = async ({ url, data }) => {
    try {
      const response = await this.base({
        method: data.method,
        url: url,
        data: data.body ? this.serialize(data.body) : {},
        withCredentials: true,
        headers: this.getExternalHeaders(),
      });

      if (response.status >= 300) {
        return response;
      }

      return response.data;
    } catch (e) {
      if (e.message.includes("401")) {
        return { unauthorized: true };
      }
    }
  };

  makeLongRequest = async ({ url, data }) => {
    try {
      const response = await this.base({
        method: data.method,
        url: url,
        data: data.body ? this.serialize(data.body) : {},
        timeout: 30000,
      });

      if (response.status >= 300) {
        return response;
      }

      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  makeHtmlRequest = async ({ url, data }) => {
    console.log("making html req");
    try {
      this.base({
        method: data.method,
        url: url,
        data: data.body ? this.serialize(data.body) : {},
        headers: this.getHtmlHeaders(),
      });
    } catch (e) {
      console.log(e);
    }
  };

  makeRequestFile = async ({ url, data, path = "" }) => {
    try {
      const response = await this.base({
        method: data.method,
        url: path + url,
        data: data.formData,
      });

      if (response.status >= 300) {
        console.log(Error(response.status), response);
      }

      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  serialize(data) {
    let serialized = {};
    if (data) {
      serialized = qs.stringify(data, {
        arrayFormat: "brackets",
        encoe: false,
      });
    }

    return serialized;
  }

  getHeaders() {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const tokenDom = document.querySelector("meta[name=csrf-token]");
    if (tokenDom) {
      const csrfToken = tokenDom.content;
      headers["X-CSRF-Token"] = csrfToken;
    }

    return headers;
  }

  getHtmlHeaders() {
    let headers = {
      "Content-Type": "text/html",
    };

    const tokenDom = document.querySelector("meta[name=csrf-token]");
    if (tokenDom) {
      const csrfToken = tokenDom.content;
      headers["X-CSRF-Token"] = csrfToken;
    }

    return headers;
  }

  getExternalHeaders() {
    let headers = this.getHeaders();
    headers["X-Requested-With"] = "XMLHttpRequest";
    return headers;
  }
}

const Api = new ApiClass();
export default Api;
