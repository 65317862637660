let validation = {
  validate(obj, passwordOnly = false) {
    let text;
    let errors = [];
    const password = obj.password;
    const passwordConf = obj.password_conf;
    let passwordErrors = [];

    if (!passwordOnly) {
      // if (!obj.email || !obj.email.includes("@") || !obj.email.includes(".")) {
      if (!validation.validateEmail(obj.email)) {
        text = "Please enter a valid email";
        errors.push({ field: "email", text: text });
      }

      if (!obj.fir_name) {
        text = "Please enter a first name";
        errors.push({ field: "fir_name", text: text });
      }

      if (!obj.las_name) {
        text = "Please enter a last name";
        errors.push({ field: "las_name", text: text });
      }

      if (!obj.zip_code || obj.zip_code.length < 5) {
        text = "Please enter a valid ZIP/postal code";
        errors.push({ field: "zipCode", text: text });
      }
    }

    if (password && password.length < 8) {
      passwordErrors.push("Password must be at least 8 characters");
      validation.passwordCheck("not-passing", "length");
    } else {
      validation.passwordCheck("passing", "length");
    }

    if (password !== passwordConf) {
      errors.push({
        field: "password_conf",
        text: "Password and password confirmation do not match",
      });
    } else if (passwordConf && passwordConf.length === 0) {
      errors.push({ field: "password_conf", text: "Please confirm password" });
    }

    if (!/[A-Z]/.exec(password) || !/[a-z]/.exec(password)) {
      passwordErrors.push(
        "Password must contain at least one uppercase and one lowercase letter"
      );
      validation.passwordCheck("not-passing", "letter");
    } else {
      validation.passwordCheck("passing", "letter");
    }

    if (!/\d/.exec(password)) {
      passwordErrors.push("Password must contain at least one number");
      validation.passwordCheck("not-passing", "number");
    } else {
      validation.passwordCheck("passing", "number");
    }

    if (passwordErrors.length > 0) {
      if (passwordOnly) {
        for (let error of passwordErrors) {
          errors.push({ field: "password", text: error });
        }
      } else {
        for (var idx in passwordErrors) {
          if (idx < passwordErrors.length - 1) {
            passwordErrors[idx] += "; ";
          }
        }
        errors.push({ field: "password", text: passwordErrors });
      }
    }

    return errors;
  },
  validateEmail(email) {
    // use rfc2822 regex to validate email
    const email_rfc2822_regex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (email_rfc2822_regex.test(email)) {
      return true;
    }
    return false;
  },
  validatePhone(phone) {
    if (!phone || phone.length < 10 || phone.length > 14) {
      return false;
    }
    return true;
  },
  validateFromOmniauth(user) {
    let text;
    let errors = [];

    if (!user.fir_name) {
      text = "Please enter a first name";
      errors.push({ field: "fir_name", text: text });
    }

    if (!user.las_name) {
      text = "Please enter a last name";
      errors.push({ field: "las_name", text: text });
    }

    if (!user.zip_code || user.zip_code.length < 5) {
      text = "Please enter a valid ZIP/postal code";
      errors.push({ field: "zipCode", text: text });
    }

    //  if (!user.company || user.company === "") {
    //    text = "Please indicate your coverage"
    //    errors.push({field: "company", text: text})
    //  }

    return errors;
  },
  passwordStrength(password) {
    let strength = 0;

    if (/\d/.exec(password)) {
      // password has a number
      strength += 1;
    }

    if (/[A-Z]/.exec(password)) {
      // password has capital letter
      strength += 1;
    }

    if (/[a-z]/.exec(password)) {
      // password has lowercase letter
      strength += 1;
    }

    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.exec(password)) {
      // password contains special character
      strength += 1;
    }

    if (password?.length >= 8) {
      // password is at least 8 characters long
      strength += 1;
    }

    return strength;
  },
  passwordDetails(strength) {
    let status = { status: "weak", color: "red" };
    if (strength >= 2) {
      status = { status: "moderate", color: "red" };
    }

    if (strength >= 4) {
      status = { status: "strong", color: "green" };
    }

    return status;
  },
  passwordCheck(status, id) {
    const obj = document.getElementById(id);
    if (obj && obj.classList) {
      if (status === "passing") {
        obj.classList.add("green");
      } else {
        obj.classList.remove("green");
      }
    }
  },
  validateDetails(user, profile, showGuardian, interestedInMAT) {
    let text;
    let errors = [];
    if (!user.fir_name || user.fir_name.length < 2) {
      errors.push({
        field: "fir_name",
        text: "First name must be two or more characters.",
      });
    }

    if (!user.las_name || user.las_name.length < 2) {
      errors.push({
        field: "las_name",
        text: "Last name must be two or more characters.",
      });
    }

    if (!user.dob || user.dob.length < 10) {
      text = "Please enter a valid date of birth (mm/dd/yyyy).";
      errors.push({ field: "dob", text: text });
    }

    if (!validation.validatePhone(user.phone)) {
      text = "Please enter a valid phone number";
      errors.push({ field: "phone", text: text });
    }

    if (!profile.gender) {
      text = "Please enter a gender";
      errors.push({ field: "gender", text: text });
    }

    if (!profile.referral || profile.referral.length === 0) {
      text = "Please tell us how you heard about us";
      errors.push({ field: "referral", text: text });
    }

    if (showGuardian && profile.guardian === undefined) {
      text = "Please enter your legal guardian status";
      errors.push({ field: "guardian", text: text });
    }

    return errors;
  },
  validateSpecifics(specifics) {
    let text;
    let errors = [];
    if (specifics.counselor === undefined) {
      text = "Please your counselor preference";
      errors.push({ text: text });
    }

    return errors;
  },
  validateSession(session) {
    let text;
    let errors = [];

    // if (
    //   !session.email ||
    //   !session.email.includes("@") ||
    //   !session.email.includes(".")
    // ) {
    if (!validation.validateEmail(obj.email)) {
      text = "Please enter a valid email";
      errors.push({ text: text });
    }

    if (session.password.length < 8) {
      text = "Please enter a valid password";
      errors.push({ text: text });
    }

    return errors;
  },
  validatePromo(promo) {
    let text;
    let errors = [];

    if (!promo) {
      text = "Please enter a promotional code";
      errors.push({ text: text });
    }

    return errors;
  },
  validateCard(payment) {
    let text;
    let errors = [];

    if (!payment.card_number || payment.card_number.length <= 14) {
      text = "Please enter a valid credit card";
      errors.push({ field: "card_number", text: text });
    }

    if (!payment.cvc || payment.cvc.length < 3) {
      text = "Please enter a valid cvc";
      errors.push({ field: "cvc", text: text });
    }

    if (!payment.exp_date || payment.exp_date.length < 5) {
      text = "Please enter a valid expiration date";
      errors.push({ field: "exp_date", text: text });
    }

    if (!payment.zip_code || payment.zip_code.length < 5) {
      text = "Please enter a valid ZIP/postal code";
      errors.push({ field: "zip_code", text: text });
    }

    return errors;
  },
};

export default validation;
