import React, { Component } from "react";

import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import icons from "patient_app/assets/videoCallIcons";
import { leaveRoom } from "patient_app/api/videoCallActions";
import { disconnectFromRoom } from "patient_app/api/videoRoomActions";
import { CLEAR_VIDEO_ROOM } from "patient_app/constants/actionTypes";

const mapDispatchToProps = (dispatch) => {
  return {
    leaveRoom: (room, audioTrack, videoTrack) => {
      dispatch(leaveRoom(room, audioTrack, videoTrack));
    },
    disconnectFromRoom: (queueId, userId) =>
      dispatch(disconnectFromRoom(queueId, userId)),
    clearRoom: () => dispatch({ type: CLEAR_VIDEO_ROOM }),
  };
};

const mapStateToProps = (state) => {
  return {
    localAudioTrack: state.videoCall.localAudioTrack,
    localVideoTrack: state.videoCall.localVideoTrack,
    room: state.videoCall.room,
    user: state.common.user,
  };
};

class VideoControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioEnabled: true,
      videoEnabled: true,
    };
  }

  render() {
    let { room } = this.props;

    let { audioEnabled, videoEnabled } = this.state;

    return (
      <div className="video-controls-container">
        <button
          key="1"
          className="custom-button"
          onClick={() => this.toggleAudio()}
        >
          {!audioEnabled ? (
            <img className="icon" src={icons.audioOff} alt="audio-off" />
          ) : (
            <img className="icon" src={icons.audioOn} alt="audio-on" />
          )}
        </button>

        <button
          key="2"
          className="custom-button"
          onClick={() => this.toggleVideo()}
        >
          {!videoEnabled ? (
            <img className="icon" src={icons.videoOff} alt="video-off" />
          ) : (
            <img className="icon" src={icons.videoOn} alt="video-on" />
          )}
        </button>

        {room && (
          <button className="custom-button" onClick={() => this.leaveRoom()}>
            <img className="icon" src={icons.callEnd} alt="hang-up" />
          </button>
        )}
      </div>
    );
  }

  leaveRoom = () => {
    if (window.confirm("End Call?")) {
      this.onRoomDisconnected();
    }
  };

  toggleAudio = () => {
    if (this.props.localAudioTrack && this.props.localAudioTrack.isStarted) {
      this.setState({ audioEnabled: !this.props.localAudioTrack.isEnabled });
      this.props.localAudioTrack.enable(!this.props.localAudioTrack.isEnabled);
    }
  };

  toggleVideo = () => {
    if (this.props.localVideoTrack && this.props.localVideoTrack.isStarted) {
      this.setState({ videoEnabled: !this.props.localVideoTrack.isEnabled });
      this.props.localVideoTrack.enable(!this.props.localVideoTrack.isEnabled);
    }
  };

  onRoomDisconnected = () => {
    this.props.leaveRoom(
      this.props.room,
      this.props.localAudioTrack,
      this.props.localVideoTrack
    );
    this.props.disconnectFromRoom(
      this.props.match.params.id,
      this.props.user.id
    );
    this.props.clearRoom();
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoControls)
);
