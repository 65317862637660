import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .Community-Container {
    padding-bottom: 2rem;
  }

  .Tabs-Community {
    grid-template-columns: 1fr;
    grid-row-gap: 0;
    max-width: 60vw;
    margin: 10rem auto;
    margin-top: 2rem;
    background-color: white;
    border: 1px solid ${variables.boxBorder};
  }

  .Tabs-Community .header {
    position: relative;
    padding: 1.75rem 2.5rem;
    box-sizing: border-box;
    height: auto;
    align-items: left;
    justify-content: space-between;
  }

  .Tabs-Community .header h2 {
    margin: 0;
    font-size: 1.4rem;
    font-family: ${variables.objBold};
    color: ${variables.darkBlue};
    letter-spacing: 0.25px;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .Tabs-Community .header h1 {
    margin: 0;
    font-size: 2rem;
    font-family: ${variables.objBold};
    color: ${variables.darkBlue};
    letter-spacing: 0.25px;
    margin-top: 2px;
    line-height: 1.7em !important;
  }

  p {
    padding: 0.5rem 2.5rem;
    font-family: ${variables.objReg};
    font-size: 1em;
    color: #283e4e;
  }

  a {
    color: ${variables.green};
    font-family: ${variables.objBold};
    text-decoration: underline;
  }

  @media only screen and (max-width: 900px) {
    .Tabs-Community {
      max-width: 80%;
    }
  }

  @media only screen and (max-width: 768px) {
    .Tabs-Community {
      max-width: 100%;
      margin: 0;
    }
  }
`;
