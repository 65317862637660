import React from "react";
import { render } from "react-dom";
import App from "../patient_app/App";
import "core-js/stable";
import "regenerator-runtime/runtime";
import sentryHelper from "../patient_app/helpers/sentryHelper";

document.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("Client-App-Entry");
  sentryHelper.init();
  render(<App />, container);
});
