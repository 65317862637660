import Api from "patient_app/api/index";
import { DateTime } from "luxon";
import textHelpers from "components/utils/textHelpers";
import Appsflyer from "patient_app/helpers/Appsflyer";
import RudderstackTracker from "patient_app/helpers/RudderstackTracker";

const parseIso = (obj, tz) => {
  return {
    ...obj,
    sa: DateTime.fromISO(obj.start_at).setZone(tz),
    ea: DateTime.fromISO(obj.end_at).setZone(tz),
  };
};

import {
  AVAILABILITIES_LOAD,
  CLINICS_LOAD,
  FETCH_PROVIDER_AVS,
  FETCH_STAFF_AVAILABILITIES,
  INSURANCE_UPDATE_STATE,
  LOAD_FINALIZED_APPOINTMENT,
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  NEW_FORM_ERRORS,
  ONBOARDING_REQUEST_START,
  ONBOARDING_REQUEST_SUCCESS,
  ONBOARDING_REQUEST_FAILURE,
  ONBOARDING_CALENDAR_LINKS,
  ONBOARDING_HEALTH_INFO,
  ONBOARDING_VALID_EMPLOYER,
  ONBOARDING_NEEDS_VERIFICATION,
  ONBOARDING_FETCH_INDUCTION_BLOCKS,
  ONBOARDING_USER_LOCATION,
  ONBOARDING_UPDATE_STATE,
  PROFILE_LOAD,
  PROFILE_UPDATE_STATE_LOCAL,
  SET_NEXT_QUESTIONNAIRE_QUESTION,
  UPDATE_AUD_QUESTIONNAIRE_QUESTION_LOCAL,
  UPDATE_ENT_QUESTIONNAIRE_QUESTION_LOCAL,
  UPDATE_ONBOARDING_PAYERS,
  USER_LOAD,
} from "patient_app/constants/actionTypes";
import trackerActions from "patient_app/helpers/trackerActions";

export const createUser = (user) => {
  const anonId = RudderstackTracker.identify(user);

  const data = {
    url: `/registrations`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: {
        user: {
          company: user.company || "consumer",
          dob: user.dob,
          email: user.email,
          password: user.password,
          password_confirmation: user.password_conf,
          phone: user.phone,
          platform: user.platform,
          pref_name: user.pref_name,
          tier: user.tier,
          token: user.token,
          treatment_type: user.treatment_type,
          rudderstack_anonymous_id: anonId,
        },
        profile: {
          state: user.state,
          zip_code: user.zip_code,
          clinic_id: user.clinic_id,
          referral: user.referral,
          referral_details: user.referral_details,
          terms_agreed_to_at: user.terms_agreed_to_at,
        },
        vices: user.vices,
        member_referral_code: user.member_referral_code || "",
        signup_from: "web",
        insurance_detail: user.insurance_detail,
        // current_opioid_use: user.current_opioid_use,
        // current_methadone_use: user.current_methadone_use,
      },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ONBOARDING_REQUEST_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: USER_LOAD, user: res.user });
        // dispatch({ type: PROFILE_LOAD, user: res.profile });
        dispatch({ type: ONBOARDING_REQUEST_SUCCESS });

        let appsflyerObj = Object.assign({}, res.user);
        Appsflyer.event("Account_Created", appsflyerObj, 2);
        Appsflyer.identifyUser(res.user.id);
        trackerActions.setCustomerUserId(res.user.id);
      } else {
        let errors = [];
        res.errors.forEach((err) => {
          errors.push({ text: err });
        });
        dispatch({ type: NEW_FORM_ERRORS, errors: errors });
        dispatch({ type: ONBOARDING_REQUEST_FAILURE });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateDemographics = (user, profile) => {
  const data = {
    url: `/registrations`,
    path: "/api/v2",
    data: {
      method: "PATCH",
      body: {
        user: {
          name: `${user.fir_name} ${user.las_name}`,
          fir_name: user.fir_name,
          pref_name: user.pref_name,
          las_name: user.las_name,
          phone: user.phone,
          dob: user.dob,
        },
        profile: profile,
      },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ONBOARDING_REQUEST_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: USER_LOAD, user: res.user });
        dispatch({ type: ONBOARDING_REQUEST_SUCCESS });
      } else {
        dispatch({ type: NEW_FORM_ERRORS, errors: res.errors });
        dispatch({ type: ONBOARDING_REQUEST_FAILURE });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateTreatmentType = (user) => {
  const data = {
    url: `/registrations/update_treatment_type`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: { user: user },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ONBOARDING_REQUEST_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: USER_LOAD, user: res.user });
        dispatch({ type: ONBOARDING_REQUEST_SUCCESS });
      } else {
        dispatch({ type: NEW_FORM_ERRORS, errors: [{ text: res.errors[0] }] });
        dispatch({ type: ONBOARDING_REQUEST_FAILURE });
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: NEW_FORM_ERRORS,
        errors: [{ text: "Something went wrong." }],
      });
      dispatch({ type: ONBOARDING_REQUEST_FAILURE });
    }
  };
};

export const fetchProviderAvs = (userId, range) => {
  const data = {
    url: `/registrations/provider_availabilities?user_id=${userId}&range=${range}`,
    path: `/api/v2`,
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        if (res.staff_availabilities) {
          //CUTOVER COMMENT when fetching staff availabilities from server, endpoint is the same.
          //I just listen for the precense of a new staff_availabilities variable to determine if we're using the new system.
          // I just return early if so. res.staff_availabilities will only be present if new scheduling system is being used.

          //parseIso defined at top of file. Translates timestamps to luxon objects to make them easier to work with.
          const formatted = res.staff_availabilities.map((av, x) => {
            return parseIso(av, res.user?.timeZone);
          });
          dispatch({
            type: FETCH_STAFF_AVAILABILITIES,
            userScheduled: res.user_scheduled,
            apptCategory: res.category,
            apptLength: res.appt_length,
            staffAvailabilities: formatted,
          });
          return;
        }
        const data = res.data;
        if (data.blocks) {
          dispatch({
            type: ONBOARDING_FETCH_INDUCTION_BLOCKS,
            inductionBlocks: data.blocks,
          });
        }

        dispatch({
          type: FETCH_PROVIDER_AVS,
          recurringAvs: data.recurring_avs,
          timeOffs: data.time_offs,
          oneTimeAvs: data.one_time_avs,
          scheduledAppts: data.scheduled_appts,
          holidays: data.holidays,
          adminIds: data.admin_ids,
          userScheduled: data.user_scheduled,
          credentialedProviders: data.credentialed_providers,
          apptLength: data.appt_length,
          apptCategory: data.category,
        });
      } else {
        dispatch({
          type: ONBOARDING_REQUEST_FAILURE,
        });

        dispatch({
          type: NEW_ERRORS,
          errors: res.error
            ? [{ text: res.error }]
            : [{ text: "We're sorry, something went wrong." }],
        });
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: ONBOARDING_REQUEST_FAILURE,
      });

      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "We're sorry, something went wrong." }],
      });
    }
  };
};

export const fetchNextLink = () => {
  const data = {
    url: `/curriculums/fetch_next_link`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      dispatch({
        type: ONBOARDING_UPDATE_STATE,
        key: "nextLink",
        value: res.link,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateROI = (roi) => {
  const data = {
    url: `/release_of_info/${roi.id}`,
    path: "/api/v1",
    data: {
      method: "PATCH",
      body: {
        release_of_info: roi,
      },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ONBOARDING_REQUEST_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: ONBOARDING_REQUEST_SUCCESS,
        });
      } else {
        dispatch({
          type: ONBOARDING_REQUEST_FAILURE,
        });

        dispatch({
          type: NEW_ERRORS,
          errors: res.errors,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchHealthInfo = () => {
  const data = {
    url: `/health_infos/fetch`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      dispatch({
        type: ONBOARDING_HEALTH_INFO,
        healthInfo: res.health_info,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateHealthInfo = (healthInfo) => {
  const data = {
    url: `/health_infos/${healthInfo.id}`,
    data: {
      method: "PATCH",
      body: {
        health_info: healthInfo,
      },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ONBOARDING_REQUEST_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: ONBOARDING_HEALTH_INFO, healthInfo: res.health_info });
        dispatch({ type: ONBOARDING_REQUEST_SUCCESS });
      } else {
        dispatch({ type: NEW_ERRORS, errors: res.errors });
        dispatch({ type: ONBOARDING_REQUEST_FAILURE });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

// this function gets called for SSIs and user scheduled counselor chats
export const createAppointment = (
  user,
  calEvent,
  billingType = null,
  router = null
) => {
  let data = {};
  // updating event
  if (calEvent.id) {
    data = {
      url: `/cal_events/${calEvent.id}`,
      data: {
        body: {
          cal_event: calEvent,
          json: true,
        },
        method: "PATCH",
      },
    };
  }
  // creating new event
  else {
    data = {
      path: "/api/v2",
      url: `/registrations/create_appointment`,
      data: {
        body: {
          cal_event: calEvent,
          json: true,
        },
        method: "POST",
      },
    };
  }

  return async (dispatch) => {
    try {
      dispatch({ type: ONBOARDING_REQUEST_START });
      const res = await Api.makeRequest(data);
      // console.log("createAppointment res", res);
      if (res.success || res.status === 200) {
        if (calEvent.category !== "counselor_chat" && billingType) {
          let appsflyerObj = Object.assign({}, user);
          appsflyerObj.billing_type = billingType;
          Appsflyer.event("Appointment_Scheduled", appsflyerObj, 2);

          // new appsflyer coded events
          Appsflyer.event("ScheduleFirstAppointment");
          if (billingType == "commercial_insurance") {
            Appsflyer.event("ScheduleFirstAppointmentCommercial");
          }
          const isInductionAppt =
            calEvent.category?.includes("induction") ||
            calEvent.category === "new_evaluation_medical";
          if (isInductionAppt) {
            trackerActions.logAppsFlyerEvent("ScheduleInduction");
            if (billingType == "commercial_insurance") {
              trackerActions.logAppsFlyerEvent("ScheduleInductionCommercial");
            }
          }
        }

        dispatch({
          type: ONBOARDING_REQUEST_SUCCESS,
        });

        dispatch({
          type: LOAD_FINALIZED_APPOINTMENT,
          event: res.appointment || res.event,
        });
      } else {
        const errors = res.errors || [{ text: res.error }];
        if (res.error_type === "payment_failed" && router) {
          router.push("/payments/purchase");

          dispatch({
            type: NEW_ERRORS,
            errors: errors,
            errorType: res.error_type,
          });

          return;
        }

        dispatch({
          type: ONBOARDING_REQUEST_FAILURE,
        });

        if (res.error_type === "double_booked") {
          dispatch({
            type: NEW_ERRORS,
            errors: [
              { text: "Appointment time taken, please select another time." },
            ],
            errorType: res.error_type,
          });
        } else {
          dispatch({
            type: NEW_ERRORS,
            errors: errors,
            errorType: res.error_type,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const createAppointmentFromStaffAvailability = (
  staffAvailabilityId,
  router
) => {
  // creating new event
  const data = {
    path: "/api/v2",
    url: `/registrations/create_appointment_from_staff_availability`,
    data: {
      body: {
        staff_availability_id: staffAvailabilityId,
        json: true,
      },
      method: "POST",
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ONBOARDING_REQUEST_START });
      const res = await Api.makeRequest(data);
      // console.log("createAppointment res", res);
      if (res.success || res.status === 200) {
        // new appsflyer coded events
        let { user, appointment } = res;
        //if (appointment.category !== "counselor_chat") {
        // Appsflyer.event("ScheduleFirstAppointment");
        // if (user.billingType == "commercial_insurance") {
        //   Appsflyer.event("ScheduleFirstAppointmentCommercial");
        //  }
        // const isInductionAppt =
        //  appointment.category?.includes("induction") ||
        //   appointment.category === "new_evaluation_medical";
        // if (isInductionAppt) {
        //trackerActions.logAppsFlyerEvent("ScheduleInduction");
        //if (user.billingType == "commercial_insurance") {
        //trackerActions.logAppsFlyerEvent("ScheduleInductionCommercial");
        //}
        //}
        // }

        dispatch({
          type: ONBOARDING_REQUEST_SUCCESS,
        });

        dispatch({
          type: LOAD_FINALIZED_APPOINTMENT,
          event: res.appointment || res.event,
        });
      } else {
        const errors = res.errors || [{ text: res.error }];
        if (res.error_type === "payment_failed" && router) {
          router.push("/payments/purchase");

          dispatch({
            type: NEW_ERRORS,
            errors: errors,
            errorType: res.error_type,
          });

          return;
        }

        dispatch({
          type: ONBOARDING_REQUEST_FAILURE,
        });

        if (res.error_type === "double_booked") {
          dispatch({
            type: NEW_ERRORS,
            errors: [
              { text: "Appointment time taken, please select another time." },
            ],
            errorType: res.error_type,
          });
        } else {
          dispatch({
            type: NEW_ERRORS,
            errors: errors,
            errorType: res.error_type,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchAppointment = (calEventId) => {
  const data = {
    url: `/appointments/${calEventId}`,
    path: "/api/v1",
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: LOAD_FINALIZED_APPOINTMENT, event: res.appointment });
        res.links &&
          dispatch({ type: ONBOARDING_CALENDAR_LINKS, links: res.links });
      }
      dispatch({ type: ONBOARDING_REQUEST_SUCCESS });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchQuestion = (id) => {
  const data = {
    url: `/questionnaire_questions/${id}/fetch_oud`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.question) {
        dispatch({
          type: UPDATE_AUD_QUESTIONNAIRE_QUESTION_LOCAL,
          question: res.question,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const submitAnswer = (question, enterprise = false) => {
  const data = {
    url: `/questionnaire_question_responses/`,
    data: {
      method: "POST",
      body: {
        questionnaire_question_response: {
          answer: question.response.answer,
          question_type: question.question_type,
          questionnaire_question_id: question.id,
          id: question.response.id,
        },
      },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ONBOARDING_REQUEST_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        if (enterprise) {
          dispatch({
            type: SET_NEXT_QUESTIONNAIRE_QUESTION,
            nextQuestionId: res.next_question.id,
          });

          if (res.next_question.category === "outcome") {
            dispatch({
              type: UPDATE_ENT_QUESTIONNAIRE_QUESTION_LOCAL,
              question: question,
              goToOutcome: true,
            });
          }
        } else {
          dispatch({
            type: UPDATE_AUD_QUESTIONNAIRE_QUESTION_LOCAL,
            question: res.updated_question,
          });

          dispatch({
            type: SET_NEXT_QUESTIONNAIRE_QUESTION,
            nextQuestionId: res.next_question_id,
          });
        }

        dispatch({
          type: ONBOARDING_REQUEST_SUCCESS,
        });
      } else {
        dispatch({
          type: ONBOARDING_REQUEST_FAILURE,
        });

        dispatch({
          type: NEW_ERRORS,
          errors: res.errors,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchVice = () => {
  const data = {
    url: `/registrations/get_vices`,
    path: "/api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: ONBOARDING_UPDATE_STATE,
          key: "primaryVice",
          value: res.primary_name,
        });
        if (res.secondary_name) {
          dispatch({
            type: ONBOARDING_UPDATE_STATE,
            key: "secondaryVice",
            value: res.secondary_name,
          });
        }

        return;
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateVice = (focus, name, label) => {
  const data = {
    url: `/registrations/select_vice`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: {
        vice: !!name ? { label: label, name: name } : "",
        focus: focus,
      },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ONBOARDING_REQUEST_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: ONBOARDING_REQUEST_SUCCESS });
        return;
      }

      dispatch({ type: ONBOARDING_REQUEST_FAILURE });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchEnterpriseQuestion = (id) => {
  let url = `/questionnaire_questions/${id}?json=true`;
  const data = {
    url: url,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.question) {
        let question = res.question;
        question.header = res.header_question;
        question.response = res.response;
        if (res.question_var) {
          question.text = textHelpers.insertVariables(
            res.question.text,
            res.question_var
          );
        }
        if (res.header_var) {
          question.header.text = textHelpers.insertVariables(
            res.header_question.text,
            res.header_var
          );
        }

        dispatch({
          type: ONBOARDING_UPDATE_STATE,
          key: "primaryVice",
          value: res.primary_vice,
        });
        dispatch({
          type: UPDATE_ENT_QUESTIONNAIRE_QUESTION_LOCAL,
          question: question,
          goToOutcome: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchOutcome = (id) => {
  let url = `/questionnaires/outcome?question_id=${id}&json=true`;
  const data = {
    url: url,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.question) {
        let question = res.question;
        if (question.has_variable) {
          question.text = textHelpers.insertVariables(
            question.text,
            res.question_var
          );
        }

        dispatch({
          type: UPDATE_ENT_QUESTIONNAIRE_QUESTION_LOCAL,
          question: question,
          goToOutcome: false,
        });
        dispatch({ type: PROFILE_LOAD, profile: res.profile });
        dispatch({ type: USER_LOAD, user: res.user });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const submitCompanyCode = (token) => {
  const data = {
    url: `/registrations/check_company_code`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: {
        company_code: token,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      console.log("submitCompanyCode res", res);
      if (res.success) {
        dispatch({
          type: ONBOARDING_UPDATE_STATE,
          key: "employer",
          value: res.company,
        });
        dispatch({
          type: ONBOARDING_UPDATE_STATE,
          key: "needsOccupation",
          value: res.needs_occupation,
        });
        dispatch({ type: USER_LOAD, user: res.user });

        if (res.needs_verification) {
          dispatch({
            type: ONBOARDING_UPDATE_STATE,
            key: "needsEmployerVerification",
            value: true,
          });
        } else {
          dispatch({
            type: ONBOARDING_VALID_EMPLOYER,
            isEnterprise: true,
          });
        }
      } else {
        dispatch({ type: NEW_FORM_ERRORS, errors: [{ text: res.errors[0] }] });
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "We're sorry, something went wrong." }],
      });
    }
  };
};

export const submitCompanyVerification = (firName, lasName, dob, companyId) => {
  const data = {
    url: `/registrations/submit_employer_verification`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: {
        fir_name: firName,
        las_name: lasName,
        dob: dob,
        company_id: companyId,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: USER_LOAD, user: res.user });
        dispatch({
          type: ONBOARDING_UPDATE_STATE,
          key: "employer",
          value: res.employer,
        });
        dispatch({
          type: ONBOARDING_VALID_EMPLOYER,
          isEnterprise: true,
          isEnterpriseConfirmed: true,
        });
      }

      dispatch({ type: NEW_FORM_ERRORS, errors: [{ text: res.errors[0] }] });
      dispatch({ type: ONBOARDING_REQUEST_FAILURE });
    } catch (e) {
      console.log(e);
    }
  };
};

export const checkLocation = (zipCode, history = null) => {
  const data = {
    url: `/onboarding/check_location?zip_code=${zipCode}`,
    data: {
      method: "GET",
      body: {},
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ONBOARDING_REQUEST_START });
      const res = await Api.makeRequest(data);
      let url;
      if (res.success) {
        dispatch({
          type: ONBOARDING_USER_LOCATION,
          state: res.state,
          stateName: res.state_name,
          recommendedClinicId: res.recommended_clinic_id,
        });
        dispatch({ type: CLINICS_LOAD, clinics: res.clinics });
        dispatch({ type: ONBOARDING_REQUEST_SUCCESS });
      } else {
        dispatch({ type: NEW_FORM_ERRORS, errors: [{ text: res.errors }] });
        dispatch({ type: ONBOARDING_REQUEST_FAILURE });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchInsuranceProviders = (state, insuranceProviderId = null) => {
  let url = `/registrations/insurance_options?state=${state}`;
  if (insuranceProviderId) {
    url = `${url}&insurance_provider_id=${insuranceProviderId}`;
  }

  const data = {
    url: url,
    path: "api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: ONBOARDING_UPDATE_STATE,
          key: "clinicPrograms",
          value: res.clinic_programs,
        });
        dispatch({
          type: INSURANCE_UPDATE_STATE,
          obj: "insuranceProviders",
          value: res.insurance_providers,
        });
        dispatch({
          type: INSURANCE_UPDATE_STATE,
          obj: "stateCoverages",
          value: res.state_coverages,
        });

        return;
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const switchToSelfPay = () => {
  const data = {
    url: `/onboarding/switch_to_self_pay`,
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ONBOARDING_REQUEST_START });
      const res = await Api.makeRequest(data);
      let url;
      if (res.success) {
        dispatch({ type: ONBOARDING_REQUEST_SUCCESS });
        window.location.href = "/payments/purchase";
      } else {
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.errors }] });
        dispatch({ type: ONBOARDING_REQUEST_FAILURE });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const reactivateStripeAccount = () => {
  const data = {
    url: `/registrations/reactivate_stripe_account`,
    path: "api/v2",
    data: { method: "POST" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ONBOARDING_REQUEST_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: ONBOARDING_REQUEST_SUCCESS });
      } else {
        dispatch({ type: ONBOARDING_REQUEST_FAILURE });
      }
    } catch (e) {
      dispatch({ type: ONBOARDING_REQUEST_FAILURE });
      console.log(e);
    }
  };
};

export const fetchProgramCost = (
  userId,
  tier,
  treatmentType,
  billingType,
  state
) => {
  let url = `/registrations/program_cost`;
  if (userId) {
    url += `?user_id=${userId}`;
  } else {
    url += `?tier=${tier}&treatment_type=${treatmentType}&billing_type=${billingType}&state=${state}`;
  }
  const data = {
    url: url,
    path: "api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: ONBOARDING_REQUEST_START });
      const res = await Api.makeRequest(data);
      dispatch({
        type: ONBOARDING_UPDATE_STATE,
        key: "programCosts",
        value: res.program_costs,
      });

      dispatch({
        type: ONBOARDING_UPDATE_STATE,
        key: "medicaidFeeStates",
        value: res.medicaid_fee_states,
      });
      dispatch({ type: ONBOARDING_REQUEST_SUCCESS });
    } catch (e) {
      dispatch({ type: ONBOARDING_REQUEST_FAILURE });
      console.log(e);
    }
  };
};

export const checkNeedsOccupation = () => {
  const data = {
    url: `/registrations/needs_occupation`,
    path: "api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: ONBOARDING_UPDATE_STATE,
          key: "needsOccupation",
          value: res.needs_occupation,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchClinicPrograms = (clinicId = "") => {
  const data = {
    url: `/registrations/clinic_programs?clinic_id=${clinicId}`,
    path: "api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: ONBOARDING_UPDATE_STATE,
          key: "clinicPrograms",
          value: res.clinic_programs,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};
