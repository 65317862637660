import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  body {
    background: ${variables.whiteGray};
  }

  #Main-Container {
    display: flex;
  }

  .content-main {
    display: flex;
    height: 100vh;
    flex-basis: 100%;
  }

  .content-main.center-vertically {
    justify-content: center;
  }

  .content-main .content-box {
    position: relative;
    margin: 3.5rem auto;
    width: 28rem;
    box-sizing: border-box;
    background-color: white;
    /* box-shadow: 0 0 30px rgba(0,0,0,0.08); */
    border: 1px solid ${variables.boxBorder};
    display: flex;
    flex-direction: column;
    flex: none;
  }

  /* .content-main.half-bleed .content-box {
    margin-top: 5rem;
    margin-bottom: 15vh;
  } */

  .content-main.center-vertically .content-box {
    top: initial;
    margin-top: initial;
    margin-bottom: initial;
    height: 70%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
  }

  .content-main .content-box.vertical-overflow {
    min-height: initial;
  }

  .content-main .content-box.taller-than-container {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    min-height: initial;
    flex: none;
  }

  .content-main .content-box.checklist {
    width: 40%;
  }

  .content-main .content-box.with-nav {
    margin-top: 6rem;
  }

  /* microsoft edge styling bug */
  _:-ms-lang(x),
  .content-main .content-box.taller-than-container {
    overflow: scroll;
  }

  .content-main .content-box.wide {
    width: 80%;
    margin: auto;
  }

  .content-main .content-box.dual-width {
    width: 50rem;
    margin: auto;
  }

  .content-main .content-box.payment {
    width: 30rem;
    margin-top: auto;
    margin-bottom: auto;
  }

  .content-main .content-box.half {
    width: 50%;
  }

  .content-main .content-box.margin-top-medium {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .content-main .content-box form {
    width: 100%;
  }

  .content-main .content-box.onboarding h1 {
    position: relative;
    font-size: 1.25rem;
    font-family: ${variables.objBold};
    text-align: left;
    color: ${variables.blueDark};
    margin: 0 0 ${variables.base};
    line-height: 33px;
  }

  .content-main .content-box.onboarding h1.with-subtitle {
    margin-bottom: ${variables.small};
  }

  .content-main .content-box.onboarding h2 {
    position: relative;
    font-size: 1rem;
    font-family: ${variables.objReg};
    text-align: left;
    color: ${variables.blueDark};
    margin: 0 0 2rem;
    line-height: 33px;
    font-weight: normal;
  }

  .content-main .content-box.onboarding .center h2 {
    text-align: center;
    font-family: ${variables.objMed};
    margin: 0;
  }

  .content-box .question-info-outline {
    position: absolute;
    top: ${variables.small};
    right: ${variables.small};
    display: inline-flex;
    padding: 2px;
    margin-left: 0.25rem;
    border: 2px solid white;
    border-radius: 50%;
    width: 1.8rem;
    height: 1.8rem;
    box-sizing: border-box;
  }

  .content-box .question-info-outline.selected {
    border: 2px solid ${variables.green};
  }

  .content-box .question-info {
    background: ${variables.gray};
    border-radius: 50%;
    width: 100%;
    height: 100%;
    color: white;
    font-family: ${variables.objBold};
    font-size: 0.8rem;
  }

  .content-box .question-info:focus {
    outline: none;
  }

  .password-validation-elements {
    margin-bottom: ${variables.base};
  }

  .condition-style {
    display: flex;
    align-items: center;
  }

  .condition-style img {
    width: ${variables.small};
    margin-right: ${variables.tiny};
  }

  .condition-style p {
    margin: 0;
  }

  .tooltip-style {
    position: absolute;
    bottom: 105%;
    left: 30%;
    z-index: 1001;
    background: white;
    border: 1px solid ${variables.green};
    padding: 5px;
    border-radius: 7px;
    right: 3%;
  }

  .tooltip-show-style {
    position: absolute;
    left: 50%;
  }

  .password-container-div {
    position: relative;
    margin-bottom: 1.4rem;
  }

  .content-main .content-box .info-box {
    background-color: ${variables.lightGray};
    padding: 2rem;
  }

  .content-main .content-box .info-box p {
    line-height: 1.5rem;
  }
  .content-main .content-box .info-box p:last-of-type {
    margin: 0;
  }

  .content-main .content-box .inputs {
    display: flex;
    flex-direction: column;
    flex: none;
    padding: 3.5rem 2.5rem;
  }

  .content-main .content-box .inputs img.full-bleed {
    height: 200px;
    width: auto;
    margin: -56px -40px 24px;
  }

  .content-main .content-box .inputs.no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .content-main .content-box .inputs.no-padding h1,
  .content-main .content-box .inputs.no-padding .align-bottom {
    box-sizing: border-box;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .under-content-box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: auto;
    transform: translate(0, calc(100% + 1rem));
  }

  .under-content-box .text {
    margin-bottom: 1rem;
  }

  .under-content-box .text p {
    text-align: center;
  }

  .notification-box {
    padding: 1.5rem 2.5rem;
    margin-bottom: 1rem;
    background-color: ${variables.boxBorder};
    border: 1px solid ${variables.boxBorder};
  }

  .notification-box p {
    font-family: ${variables.objReg};
    font-size: 0.8rem;
    color: ${variables.blueDark};
    line-height: 1.25rem;
    margin: 0px;
  }

  .onboarding-progress-bar {
    width: 100%;
    height: 0.5rem;
    color: white;
  }

  .onboarding-progress-bar .filler {
    background-color: ${variables.green};
    height: 100%;
    transition: width 0.2s ease-in;
  }

  p {
    font-family: ${variables.objReg};
    font-size: 0.8rem;
    line-height: 1.5rem;
    color: ${variables.blueDark};
  }

  a {
    font-family: ${variables.objReg};
    font-size: 0.8rem;
    color: ${variables.green};
    text-decoration: underline;
  }

  span {
    font-family: ${variables.objBold};
  }

  p.description {
    margin-bottom: 2rem;
    font-family: ${variables.objReg};
    font-size: 0.8rem;
    text-align: left;
  }

  p.description.no-margin {
    margin: 0;
  }

  p.description.info {
    font-size: 0.8rem;
    font-family: ${variables.objMed};
    color: ${variables.lightText};
  }

  p.description.hidden {
    display: none;
  }

  p.description a {
    font-size: 0.8rem;
    font-family: ${variables.objMed};
  }

  p.field-specific-error {
    font-family: ${variables.objReg};
    font-size: 0.7rem;
    color: ${variables.red};
    margin-top: 0;
    margin-bottom: 1rem;
  }

  p.field-error {
    font-family: ${variables.objReg};
    font-size: 0.7rem;
    color: ${variables.red};
    margin: 0;
  }

  p.required {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(0, calc(100% + 0.5rem));
    font-family: ${variables.objReg};
    font-size: 0.8rem;
    text-align: center;
    color: ${variables.lightText};
    width: 100%;
    margin: 0;
  }

  p.required.web-only,
  p.required.mobile-only {
    position: relative;
    bottom: initial;
    transform: initial;
  }

  p.web-only {
    display: block;
  }
  p.mobile-only {
    display: none;
  }

  p.note {
    font-family: ${variables.objReg};
    font-size: 0.8rem;
    color: ${variables.lightText};
  }

  :global(.field input),
  :global(.field.select select) {
    height: 3rem;
  }

  :global(.field.select i.ico-arrow-down) {
    top: 2.8rem;
  }

  :global(body #Main-Container .select-button),
  :global(body #Main-Container .submit-button) {
    padding: ${variables.tiny} calc(${variables.tiny} * 3);
    height: calc(16px * 3);
    font-family: ${variables.objMed};
    margin-bottom: ${variables.small};
    font-size: 1rem;
  }

  :global(body #Main-Container .select-button.allow-overflow) {
    height: initial;
  }

  :global(body #Main-Container .select-button.taller) {
    height: 3.5rem;
  }

  body #Main-Container button.select-button.full-width.padded-bottom {
    margin-bottom: 1.25rem;
  }

  :global(body #Main-Container .select-button.green) {
    background-color: ${variables.green};
    color: white;
  }

  body #Main-Container .onboarding a.select-button {
    display: flex;
    text-decoration: none;
    width: 100%;
    margin: 0 0 1rem;
  }

  button.select-onboarding-button {
    border: 1px solid ${variables.green};
    border-radius: 4px;
    color: ${variables.green};
    background-color: white;
    margin-bottom: 1rem;
  }

  button.select-onboarding-button i {
    color: ${variables.green};
  }

  button.select-onboarding-button.selected,
  button.select-onboarding-button.selected i {
    color: white;
    background-color: ${variables.green};
  }

  .align-center {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  .align-bottom {
    width: 100%;
    display: block;
  }

  .align-bottom.padded {
    margin-top: 3rem;
  }
  .align-bottom.fill {
    height: 100%;
  }

  .input-field {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
  }

  .input-field label {
    display: block;
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    color: ${variables.blueDark};
  }

  label span.light {
    color: ${variables.lightText};
    font-family: ${variables.objMed};
  }

  .input-field input,
  .input-field select {
    height: 3rem;
    background: none;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid ${variables.blueDark};
    color: ${variables.blueDark};
    margin-bottom: 0.25rem;
    padding-left: 1rem;
    padding-right: 2.2rem;
    font-size: 0.8rem;
    font-family: ${variables.objLight};
    width: 100%;
    outline: none;
  }

  .input-field .search-icon {
    position: absolute;
    right: 0.5rem;
    top: 0.75rem;
    width: 1.3rem;
  }

  .input-field .checkbox {
    display: flex;
  }
  .input-field .checkbox input {
    width: 20px;
    height: fit-content;
  }

  @media only screen and (max-width: 600px) {
    .input-field .checkbox input {
      padding: initial;
      height: initial;
      width: initial;
    }
  }

  .input-field .checkbox label {
    color: ${variables.blueDark};
    position: relative;
    top: initial;
    left: initial;
    margin: 0;
  }

  .center-horizontal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .consent-fields .field.checkbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  legend {
    width: 100%;
  }

  legend.select-button-legend {
    text-align: center;
    line-height: 1.25rem;
    margin: 0;
    padding: 0;
    height: fit-content;
  }

  legend.select-button-legend.padded {
    margin-bottom: 3.5rem;
  }

  legend.select-button-legend.occupation {
    margin: 0;
    padding-top: 4rem;
  }

  .check-list .list-item {
    display: flex;
    margin-bottom: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .check-list .list-item label {
    margin-left: 0.5rem;
    font-size: 0.7rem;
    font-family: ${variables.objReg};
    color: ${variables.blueDark};
  }

  .check-list .list-item input[type="checkbox"] {
    margin-top: 0.25rem;
  }

  .sign-in-text {
    text-align: center;
    margin-bottom: 0.25rem;
    align-self: center;
    line-height: 1.25rem;
  }

  .sign-in-text,
  .sign-in-text a {
    font-size: 0.8rem;
    font-family: ${variables.objMed};
  }

  .content-side {
    display: none;
  }

  fieldset {
    position: relative;
  }

  fieldset .align-bottom {
    width: 100%;
  }
  fieldset .align-bottom.shorter {
    height: 80%;
  }
  fieldset .align-bottom.occupation {
    height: 15rem;
  }

  .terms-conditions {
    line-height: 1rem;
    margin-bottom: 0;
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }

  .terms-conditions.red {
    color: ${variables.red};
  }

  .terms-conditions a {
    font-size: 0.8rem;
  }

  .onboarding .calendar-links-container {
    width: 100%;
  }
  .onboarding .calendar-links {
    margin-bottom: 2rem;
    padding: 1rem 0;
    border-top: 1px solid ${variables.boxBorder};
    border-bottom: 1px solid ${variables.boxBorder};
  }

  .onboarding .calendar-links .link {
    display: inline-block;
    margin-right: 2rem;
    text-decoration: none !important;
  }

  .onboarding .calendar-links .link img {
    width: 2rem;
    height: 2rem;
  }

  .Conclusion .onboarding button.link {
    display: block;
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    color: ${variables.green};
    text-decoration: underline;
    margin-bottom: 2rem;
  }

  .onboarding .custom-button.other-option {
    font-size: 1rem;
    font-family: ${variables.objMed};
    color: ${variables.green};
    margin-bottom: 1rem;
    height: 3rem;
    border: 1px solid ${variables.green};
    border-radius: 4px;
    width: 100%;
  }

  .insurance-images {
    margin: 40px -40px 40px;
  }

  @media only screen and (max-width: 768px) {
    body {
      background: white;
    }

    .tooltip-style {
      bottom: 105%;
      left: 16%;
    }

    .content-main .content-box .inputs img.full-bleed {
      height: auto;
      width: calc(100% + 80px);
      margin: -56px -40px 24px;
    }

    .content-main .content-box,
    .content-main .content-box.taller-than-container {
      border: none;
      margin: 0;
      width: 100vw;
      height: 100vh;
      min-height: 100vh;
      min-height: -webkit-fill-available;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }

    .content-main .content-box.with-nav {
      margin-top: 4.5rem;
    }

    .under-content-box {
      position: relative;
      margin: 0;
      transform: none;
    }

    .under-content-box .notification-box {
      order: 2;
      margin: 0;
    }

    .under-content-box .text {
      padding: 0;
    }

    .under-content-box .text p {
      text-align: left;
    }

    .block_mobile,
    .content-box .inputs.align-top.header-block,
    .content-box .inputs.header-block,
    .content-main .content-box .header.header-block {
      display: block;
    }

    .align-bottom {
      display: block;
    }

    .mobile-align-bottom {
      height: 80%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .mobile-align-bottom.aud_med_his {
      margin-bottom: 8rem;
    }

    fieldset .align-bottom {
      position: relative;
      width: 100%;
      bottom: initial;
    }

    fieldset.minheight {
      min-height: initial;
    }
    fieldset.minheight-small {
      min-height: 16rem;
    }

    .content-main .content-box.onboarding h1 {
      position: relative;
      display: inline-block;
      align-items: center;
      font-size: 1.4rem;
      line-height: 2rem;
      text-align: left;
    }

    p.required {
      display: relative;
      bottom: initial;
      transform: initial;
      text-align: left;
    }

    p.web-only {
      display: none;
    }
    p.mobile-only {
      display: block;
    }

    p.description,
    p.description a,
    .sign-in-text,
    .sign-in-text a,
    .terms-conditions,
    .terms-conditions a {
      align-self: auto;
      text-align: left;
    }

    .sign-in-text {
      margin-bottom: 0.5rem;
    }

    .terms-conditions {
      margin: 0 0 2rem;
    }

    :global(.field label) {
      text-align: left;
    }

    :global(.field.select i.ico-arrow-down) {
      top: 3.5rem;
    }

    .check-list .list-item label {
      font-size: 1rem;
    }

    legend.select-button-legend {
      text-align: left;
    }

    legend.select-button-legend.occupation {
      padding-bottom: 3rem;
      padding-top: 1rem;
    }

    legend.select-button-legend.extra-small {
      font-size: 0.8rem;
      line-height: 1.25rem;
    }

    fieldset .align-bottom {
      height: fit-content;
    }

    :global(body #Main-Container .select-button) {
      height: fit-content;
      min-height: 3rem;
    }
  }

  .divider {
    width: 100%;
    height: 2px;
    background: ${variables.boxBorder};
    margin-top: ${variables.base};
    margin-bottom: ${variables.base};
  }
`;
