import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

import { curriculum } from "patient_app/assets/curriculum";
import dashboardBlockStyles from "patient_app/stylesheets/appointments/dashboardBlockStyles";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

class ProgressCircle extends React.Component {
  render() {
    let {
      size,
      strokeWidth,
      strokeDasharray,
      strokeDashoffset,
      percent,
    } = this.props;

    const center = size / 2;
    const r = center - strokeWidth / 2;
    const circumference = 2 * Math.PI * r;
    const offset = (1 - percent) * circumference;

    return (
      <div className="progress-circle" style={{ height: `${size}px` }}>
        <svg width={size} height={size}>
          <circle
            className="progress-circle-outline"
            cx={center}
            cy={center}
            r={r}
            strokeWidth={strokeWidth}
          />

          <circle
            className="progress-circle-fill"
            cx={center}
            cy={center}
            r={r}
            strokeWidth={strokeWidth}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={offset}
          />
        </svg>

        <img src={curriculum.playGreen} className="play-icon" />
        <style jsx>{dashboardBlockStyles}</style>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressCircle);
