import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .field.checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .field.checkbox input {
    width: fit-content;
    height: fit-content;
    margin: 0;
    margin-right: 0.7rem;
    padding: 0;
  }

  .field.checkbox label {
    margin: 0;
    padding: 0;
  }

  body #Main-Container .general-resources a.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    border-bottom: 1px solid ${variables.grayTrim};
    height: 4rem;
    width: 100%;
    box-sizing: border-box;
    color: ${variables.blueDark};
    text-decoration: none;
    font-size: 0.85rem;
    font-family: ${variables.objReg};
  }

  .general-resources .row:last-of-type {
    border-bottom: none;
  }

  .general-resources .row p,
  .general-resources .row a {
    font-size: 0.85rem;
    font-family: ${variables.objReg};
    margin: 0 0.5rem 0 0;
  }
`;
