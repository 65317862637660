import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import { NEW_FORM_ERRORS } from "patient_app/constants/actionTypes";

import commonAssets from "patient_app/assets";

const mapDispatchToProps = (dispatch) => {
  return {
    updateErrors: (errors) =>
      dispatch({ type: NEW_FORM_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {
    errors: state.common.formErrors,
  };
};

class FormErrors extends React.Component {
  render() {
    let { errors, classNames } = this.props;

    let style =
      errors && errors.length > 0 ? {} : { margin: 0, display: "none" };

    return (
      <div
        className={cn(
          "Errors Errors-Container Forms",
          classNames,
          errors && errors.length > 0 && "shown"
        )}
        style={style}
      >
        {errors && errors.length > 0 && this.renderErrors(errors)}
      </div>
    );
  }

  renderErrors(errors) {
    return errors.map((e, i) => {
      let status = "error";
      let precursorText = "Error: ";
      let textToDisplay = e.text;
      if (e.status) {
        status = e.status;
      }

      if (status === "info") {
        precursorText = "Alert: ";
      }

      textToDisplay = precursorText && `${precursorText}${textToDisplay}`;
      let fieldId = e.uid ? `aria-describedby-${e.uid}` : `general-error-${i}`;

      return (
        <div className={cn("notification", status)} key={i}>
          {e.action && e.callback ? (
            <p>
              {textToDisplay}{" "}
              <a href="#" onClick={e.callback}>
                {e.action}
              </a>
            </p>
          ) : (
            <p
              id={fieldId}
              role="alert"
              dangerouslySetInnerHTML={{ __html: textToDisplay }}
            />
          )}

          <button
            className="custom-button close focus-point-notification-form-error"
            tabIndex="0"
            aria-label={`dismiss ${status === "info" ? "alert" : "error"}`}
            aria-describedby={fieldId}
            onClick={(event) => this.removeError(e)}
          >
            <img
              src={
                status === "error"
                  ? commonAssets.redCross
                  : commonAssets.greenCross
              }
              alt="close"
            />
          </button>
        </div>
      );
    });
  }

  removeError = (error) => {
    let { errors } = this.props;

    let updatedErrors = [...errors];
    const errorToRemove = _.find(updatedErrors, { text: error.text });

    if (!errorToRemove) {
      return;
    }

    _.remove(updatedErrors, errorToRemove);
    this.props.updateErrors(updatedErrors);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormErrors)
);
