import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

class MainLayout extends React.Component {
  render() {
    return (
      <div id="Main-Container" className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainLayout)
);
