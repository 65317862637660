import PropTypes from "prop-types";
import React from "react";

import { connect } from "react-redux";
import Cleave from "cleave.js/react";
import layout from "patient_app/stylesheets/profiles/layout";
import textHelpers from "../../../helpers/textHelpers";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    fieldSpecificErrors: state.common.fieldSpecificErrors,
  };
};

class ProfileField extends React.Component {
  render() {
    let {
      className,
      field,
      fieldSpecificErrors,
      fieldType,
      formatting,
      options,
      required,
      disabled,
      showBlankOption,
      showLabel,
      showSubtitle,
      title,
      value,
    } = this.props;

    const error = fieldSpecificErrors[field];
    showLabel = [null, undefined, true].includes(showLabel);
    if (["multi-checkbox", "checkbox"].includes(fieldType)) {
      showLabel = false;
    }

    return (
      <div className={`profile-field ${fieldType} ${className}`}>
        <div className="row">
          {showLabel && (
            <label htmlFor={field}>
              {title} {required && <span className="required">(Required)</span>}
            </label>
          )}

          {showSubtitle && this.props.children}

          {fieldType === "text" && (
            <input
              id={field}
              value={value ? value : ""}
              disabled={this.props.disabled}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              type="text"
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}

          {fieldType === "textarea" && (
            <textarea
              id={field}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              type="text"
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}

          {fieldType === "select" && (
            <select
              id={field}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            >
              {(showBlankOption || showBlankOption === undefined) && (
                <option key="0" value={""}>
                  -
                </option>
              )}
              {options &&
                options.length > 0 &&
                options.map((x, i) => {
                  return (
                    <option key={i} value={typeof x === "object" ? x[1] : x}>
                      {typeof x === "object" ? x[0] : x}
                    </option>
                  );
                })}
            </select>
          )}

          {fieldType === "phone" && (
            <Cleave
              id={field}
              type="text"
              options={{ phone: true, phoneRegionCode: "us" }}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}

          {fieldType === "date" && (
            <Cleave
              id={field}
              type="text"
              placeholder="mm/dd/yyyy"
              disabled={this.props.disabled}
              options={{
                date: true,
                datePattern: ["m", "d", "Y"],
                dateMin: "1700-01-01",
                dateMax: "2050-01-01",
              }}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}

          {fieldType === "number" && (
            <Cleave
              id={field}
              type="text"
              options={{ numeral: true }}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}

          {fieldType === "custom" && (
            <Cleave
              id={field}
              type="text"
              options={formatting}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}

          {fieldType === "checkbox" && (
            <div className="checkbox">
              <input
                id={field}
                type="checkbox"
                checked={value ? value : false}
                onChange={(e) =>
                  this.props.onUpdateField(e.target.checked, field)
                }
                aria-invalid={error ? true : false}
                aria-describedby={error && `aria-describedby-${field}`}
              />
              <label htmlFor={field}>{title}</label>
            </div>
          )}

          {fieldType === "multi-checkbox" && (
            <fieldset
              aria-describedby={error && `aria-describedby-${field}`}
              aria-invalid={error ? true : false}
              aria-label={title}
            >
              <legend>{title}</legend>
              {options.map((option, i) => {
                return (
                  <div className="checkbox" key={i}>
                    <input
                      id={`${field}-${i}`}
                      type="checkbox"
                      checked={value.includes(option) ? true : false}
                      onChange={(e) => this.props.onUpdateField(option, field)}
                      aria-invalid={error ? true : false}
                      aria-describedby={error && `aria-describedby-${field}`}
                    />
                    <label htmlFor={`${field}-${i}`}>{option}</label>
                  </div>
                );
              })}
            </fieldset>
          )}

          {fieldType === "plain-text" && (
            <p
              id={field}
              className={showLabel ? "" : "no-label"}
              aria-label={showLabel ? null : title}
            >
              {field === "gender_identity"
                ? textHelpers.titleize(value)
                : value}
            </p>
          )}

          {fieldType === "plain-text-password" && (
            <input
              id={field}
              className={showLabel ? "" : "no-label"}
              value={[null, undefined, ""].includes(value) ? "N/A" : value}
              type="password"
              aria-label={showLabel ? null : title}
              readOnly={true}
              disabled={true}
            />
          )}
        </div>

        {error && <p className="field-error">{error}</p>}

        <style jsx>{layout}</style>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileField);
