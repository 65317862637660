import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import { BrowserRouter as Router, withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  createOrder,
  fetchOrders,
  updateOrder,
} from "patient_app/api/drugTestOrderActions";
import {
  DTO_PAGE_LOAD,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
  NEW_ERRORS,
} from "patient_app/constants/actionTypes";

import Nav from "patient_app/components/navbar/Nav";
import MainLayout from "patient_app/components/layouts/MainLayout";

import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import NumericField from "patient_app/components/input_fields/NumericField";
import SubmitButton from "patient_app/components/buttons/SubmitButton";

import assets from "patient_app/assets";
import layout from "patient_app/stylesheets/drug_test_orders/layout";
import profileLayout from "patient_app/stylesheets/profiles/layout";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    newOrder: state.drugTestOrder.newOrder,
  };
};

class DTOSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [
        {
          url: `/drug_test_orders/new`,
          disabled: false,
          current: true,
          text: "Unused Tests",
        },
        {
          url: "",
          disabled: true,
          current: false,
          text: "Order Tests",
        },
        {
          url: "",
          disabled: true,
          current: false,
          text: "Shipping Address",
        },
        {
          url: "",
          disabled: true,
          current: false,
          text: "Confirm Your Address",
        },
        {
          url: "",
          disabled: true,
          current: false,
          text: "Payment Method",
        },
        {
          url: "",
          disabled: true,
          current: false,
          text: "Delivery Options",
        },
        {
          url: "",
          disabled: true,
          current: false,
          text: "Review Order",
        },
        {
          url: "",
          disabled: true,
          current: false,
          text: "Confirmation",
        },
      ],
      pathname: "",
    };
  }

  componentDidMount() {
    let links = this.state.links;

    this.props.newOrder && this.setLinks(this.props.newOrder.id);
    this.getCurrentLink();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.newOrder && this.props.newOrder) {
      this.setLinks(this.props.newOrder.id);
      this.getCurrentLink();
    }

    if (this.state.pathname !== this.props.location.pathname) {
      this.getCurrentLink();
    }
  }

  render() {
    let { newOrder } = this.props;
    let { links } = this.state;

    return (
      <nav
        className="profile-sidebar"
        role="navigation"
        aria-label="Drug test order navigation"
      >
        {links.map((link, i) => {
          if (["Payment Method", "Delivery Options"].includes(link.text)) {
            return;
          }

          return (
            <NavLink
              to={link.url || "/drug_test_orders/new"}
              className={cn(
                "tab",
                link.disabled ? "disabled" : "",
                link.current ? "current" : ""
              )}
              key={i}
            >
              {link.text}
              {!link.disabled && !link.current && (
                <img src={assets.completedGreenCircle} alt="checkmark" />
              )}
            </NavLink>
          );
        })}
        <style jsx>{layout}</style>
      </nav>
    );
  }

  setLinks = (orderId) => {
    let links = this.state.links;
    links[0].url = `/drug_test_orders/new?order_id=${orderId}`;
    links[1].url = `/drug_test_orders/${orderId}/quantity`;
    links[2].url = `/drug_test_orders/${orderId}/address`;
    links[3].url = `/drug_test_orders/${orderId}/verify_address`;
    links[4].url = `/drug_test_orders/${orderId}/payment`;
    links[5].url = `/drug_test_orders/${orderId}/delivery`;
    links[6].url = `/drug_test_orders/${orderId}/review`;
    links[7].url = `/drug_test_orders/${orderId}/confirmation`;
    this.setState({ links: links });
  };

  getCurrentLink = () => {
    let currentIdx = 0;
    const currentUrl = this.props.location.pathname;
    if (currentUrl.includes("/quantity")) {
      currentIdx = 1;
    } else if (currentUrl.includes("/address")) {
      currentIdx = 2;
    } else if (currentUrl.includes("/verify_address")) {
      currentIdx = 3;
    } else if (currentUrl.includes("/payment")) {
      currentIdx = 4;
    } else if (currentUrl.includes("/delivery")) {
      currentIdx = 5;
    } else if (currentUrl.includes("/review")) {
      currentIdx = 6;
    } else if (currentUrl.includes("/confirmation")) {
      currentIdx = 7;
    }

    let links = this.state.links;
    for (var i = 0; i < links.length; i++) {
      links[i].current = false;

      if (i <= currentIdx) {
        links[i].disabled = false;
      } else {
        links[i].disabled = true;
      }

      if (i === currentIdx) {
        links[i].current = true;
      }
    }

    this.setState({ links: links, pathname: currentUrl });
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DTOSidebar)
);
