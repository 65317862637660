import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { mobileCheck } from "patient_app/helpers/supported";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";

import commonAssets from "patient_app/assets";
import layout from "patient_app/stylesheets/onboarding/layout";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

class ContentBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: null,
    };
  }

  // checkMobile = () => {
  //   if (this.isSmallScreen()) {
  //     this.setState({ height: window.innerHeight })
  //   }
  //   else {
  //     this.setState({ height: null })
  //   }
  // }

  // isSmallScreen() {
  // 	try {
  // 		const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  // 		return (w <= 600);
  // 	}
  // 	catch(e) {
  // 		console.log(e)
  // 		return false;
  // 	}
  // }

  componentDidMount = () => {
    // this.checkMobile()
    // window.addEventListener("resize", this.checkMobile);
  };

  componentWillUnmount = () => {
    // window.removeEventListener("resize", this.checkMobile);
  };

  render() {
    let { className } = this.props;
    // let { height } = this.state;

    return (
      <div className={cn("content-box", className)}>
        {this.props.children}

        <style jsx>{layout}</style>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentBox)
);
