import PropTypes from "prop-types";
import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import { SESSION_ACTIVATION_PAGE_LOAD } from "patient_app/constants/actionTypes";

import MainLayout from "patient_app/components/layouts/MainLayout";
import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import SubmitButton from "patient_app/components/buttons/SubmitButton";

import { mobileCheck, getOS, getBrowser } from "patient_app/helpers/supported";

import onboardingLayout from "patient_app/stylesheets/onboarding/layout";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

class DownloadApp extends React.Component {
  componentDidMount = async () => {
    document.title = "Download App | Workit Health";
    this.checkForNativeRedirect();
  };

  render() {
    const os = getOS() || "";
    let link = null;

    if (os.toLowerCase() === "ios") {
      link = "itms-apps://itunes.apple.com/app/id1383957273?mt=8";
    } else if (os.toLowerCase() === "android") {
      link =
        "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.workithealth.workitapp";
    }

    return (
      <MainLayout>
        <ContentMain>
          <ContentBox className="onboarding activate">
            <div className="inputs">
              <h1>Download the App</h1>

              {link ? (
                <div>
                  <p className="description">
                    If you are not automatically redirected, click the button
                    below to download the app.
                  </p>

                  <SubmitButton
                    text={`Go to ${
                      os.toLowerCase() === "android" ? "Google Play" : "App"
                    } Store`}
                    onSubmit={() => (window.location.href = link)}
                  />
                </div>
              ) : (
                <p className="description">
                  To download the Workit Health app, search for Workit Health in
                  your phone's{" "}
                  {os.toLowerCase() === "android" ? "Google Play" : "App"}{" "}
                  Store.
                </p>
              )}
            </div>
          </ContentBox>
        </ContentMain>

        <style jsx>{onboardingLayout}</style>
      </MainLayout>
    );
  }

  checkForNativeRedirect = () => {
    const isMobile = mobileCheck();
    let os;
    let appStoreLink = "itms-apps://itunes.apple.com/app/id1383957273?mt=8";
    // let appStoreLink = "https://itunes.apple.com/us/app/id1383957273";

    if (!isMobile) {
      return;
    }

    os = getOS();
    if (os && os === "Android") {
      appStoreLink =
        "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.workithealth.workitapp";
      // appStoreLink = "market://details?id=com.workithealth.workitapp;
    }

    try {
      window.open(appStoreLink);
    } catch (e) {
      console.log(e);
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DownloadApp)
);
