import React from "react";
import cn from "classnames";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "patient_app/api/commonActions";
import { PROFILE_MAIN_TAB } from "patient_app/constants/actionTypes";

import assets from "patient_app/assets";
import icons from "patient_app/assets/icons";
import permissions from "patient_app/helpers/permissions";
import textHelpers from "patient_app/helpers/textHelpers";

const mapDispatchToProps = (dispatch) => {
  return {
    setMainTab: (path) => dispatch({ type: PROFILE_MAIN_TAB, path: path }),
    signOut: () => dispatch(signOut()),
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.common.profile,
    user: state.common.user,
  };
};

class Dropdown extends React.Component {
  render() {
    let { disabled, expanded, profile, user } = this.props;

    let name = textHelpers.formatName(user);

    return (
      <div className={`dropdown-container ${disabled ? "disabled" : ""}`}>
        <button
          onClick={() => this.props.onToggleDropdown()}
          className="custom-button nav-trigger"
          aria-expanded={expanded}
          aria-label="toggle navigation dropdown"
        >
          <div className="trigger mobile">
            <i className="ico ico-menu ico--medium"></i>
          </div>

          <div className="trigger desktop">
            {name}
            <img className="arrow" src={assets.chevronDown} alt="Open menu." />
          </div>
        </button>

        <div className={cn("links", expanded && "expanded")}>
          {user && profile && this.renderLinks(user, profile)}
          {!user && this.renderUnauthenticatedLinks()}
        </div>
      </div>
    );
  }

  renderLinks(user, profile) {
    let imageUrl = profile && profile.image;
    if (imageUrl?.url) {
      imageUrl = imageUrl.url;
    }

    return (
      <ul>
        <li className="header mobile">
          <img src={imageUrl} alt="Profile picture" />
          <div>
            <div>
              {user.fir_name} {user.las_name}
            </div>
            <div>Workit {permissions.isClinic(user) ? "Clinic" : "Health"}</div>
          </div>
        </li>

        <li>
          <Link to="/appointments">Dashboard</Link>
        </li>

        <li>
          <Link to="/courses">Courses</Link>
        </li>

        <li>
          <Link to="/community">Community</Link>
        </li>

        <li>
          <Link
            to={`/profiles/${profile.id}`}
            onClick={() => this.props.setMainTab(`/profiles/${profile.id}`)}
          >
            Account
          </Link>
        </li>

        <li>
          <button
            className="custom-button"
            name="sign-out"
            onClick={() => this.props.signOut()}
          >
            Sign out
          </button>
        </li>

        <li className="footer mobile">
          <button
            className=" custom-button"
            aria-label="collapse navbar"
            onClick={() => this.props.onToggleDropdown()}
          >
            <img
              src={icons.arrows.arrowBackWhite}
              alt="Collapse Navbar arrow"
            />
          </button>
        </li>
      </ul>
    );
  }

  renderUnauthenticatedLinks() {
    return (
      <ul>
        <li>
          <a href={`/users/sign_in`}>Sign in</a>
        </li>
      </ul>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
