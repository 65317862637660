import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchNotifications,
  updatePreference,
} from "patient_app/api/profileActions";
import {
  NEW_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import ProfileField from "patient_app/views/profiles/partials/ProfileField";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import MainTabBack from "patient_app/views/profiles/partials/MainTabBack";

import assets from "patient_app/components/chat/assets";
import { mobileCheck } from "patient_app/helpers/supported";
import { getUrlVars } from "patient_app/helpers/linkHelpers";
import permissions from "patient_app/helpers/permissions";

import notificationStyles from "patient_app/stylesheets/profiles/notificationStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotifications: (profileId, feedback) =>
      dispatch(fetchNotifications(profileId)),
    updatePreference: (profileId, preference) =>
      dispatch(updatePreference(profileId, preference)),
    newFieldSpecificError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {
    primaryDiagnosis: state.common.primaryDiagnosis,
    preference: state.profile.preference,
    loading: state.profile.loading,
    success: state.profile.success,
    user: state.common.user,
  };
};

class Notifications extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {
      preferenceChanges: null,
    };
  }

  componentDidMount() {
    document.title = "Notifications | Workit Health";
    this.props.fetchNotifications(this.profileId);
    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);

    if (this.props.preference) {
      this.setState({ preferenceChanges: this.props.preference });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.preference && this.props.preference) {
      // when preference is fetched
      this.setState({ preferenceChanges: this.props.preference });
    }

    if (
      !prevProps.success &&
      this.props.success &&
      this.state.preferenceChanges
    ) {
      // when preference is successfully updated
      this.setState({ preferenceChanges: this.props.preference });
    }
  }

  render() {
    let { loading, preference, user } = this.props;
    let { preferenceChanges } = this.state;

    let hourInTwelve =
      preferenceChanges &&
      this.getHourInTwelveHourFormat(preferenceChanges.daily_reminder_hour);
    let meridian =
      preferenceChanges &&
      this.getMeridian(preferenceChanges.daily_reminder_hour);

    const hasEmail =
      user && preferenceChanges && this.hasAnySelected(user, "email");
    const hasText =
      user && preferenceChanges && this.hasAnySelected(user, "text");
    const hasPush =
      user && preferenceChanges && this.hasAnySelected(user, "push");
    console.log("pref changes", this.state.preferenceChanges);
    return (
      <div>
        <MainTabBack />

        <div className="profile-section last">
          <h1>Notifications</h1>

          {!preferenceChanges && <LoadingSpinner />}

          {preferenceChanges && (
            <div>
              <div className="profile-list preference">
                <div className="list-item header">
                  <div className="row">
                    <p>Notification</p>
                    <div className="col align-center">
                      <p>Email</p>
                    </div>
                    <div className="col align-center">
                      <p>Text</p>
                    </div>
                    <div className="col align-center">
                      <p>Push</p>
                    </div>
                  </div>
                </div>

                <div className="list-item">
                  <div className="row">
                    <p>Select/Deselect All</p>
                    <div className="col align-center">
                      <input
                        type="checkbox"
                        checked={hasEmail ? true : false}
                        onChange={() => this.handleSelectAll("email", hasEmail)}
                        aria-label={`Select all email notifications`}
                      />
                    </div>

                    <div className="col align-center">
                      <input
                        type="checkbox"
                        checked={hasText ? true : false}
                        onChange={() => this.handleSelectAll("text", hasText)}
                        aria-label={`Select all text notifications`}
                      />
                    </div>

                    <div className="col align-center">
                      <input
                        type="checkbox"
                        checked={hasPush ? true : false}
                        onChange={() => this.handleSelectAll("push", hasPush)}
                        aria-label={`Select all push notifications`}
                      />
                    </div>
                  </div>
                </div>

                {this.renderPreferences()}

                {preferenceChanges.daily_reminders.includes("push") && (
                  <div className="list-item">
                    <div className="row time">
                      <p>Daily Reminder Time</p>

                      <div className="col align-center">
                        <ProfileField
                          field="reminder_hour"
                          fieldType="select"
                          onUpdateField={this.handleUpdateTime}
                          options={_.range(1, 13)}
                          showBlankOption={false}
                          showLabel={false}
                          title="Time"
                          value={hourInTwelve}
                        />

                        <ProfileField
                          field="reminder_meridian"
                          fieldType="select"
                          onUpdateField={this.handleUpdateTime}
                          options={["AM", "PM"]}
                          showBlankOption={false}
                          showLabel={false}
                          title="Time"
                          value={meridian}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <button
                className={`custom-button submit half ${
                  loading ? "loading" : ""
                }`}
                onClick={this.handleSubmit}
              >
                Save Changes
              </button>

              <p className="under-submit">
                If you are not receiving text notifications and you've replied
                STOP in a text conversation, you will need to reply START in
                that conversation to begin receiving texts again.
              </p>
            </div>
          )}
        </div>

        <style jsx>{notificationStyles}</style>
      </div>
    );
  }

  getPreferencesByTier(user) {
    const { primaryDiagnosis } = this.props;
    let allPreferences = [];
    if (permissions.isCounselor(user)) {
      allPreferences.push(["Upcoming Appointments", "appointments"]);
    } else if (permissions.isAud(user, primaryDiagnosis)) {
      allPreferences.push(["Upcoming Appointments", "appointments"]);
      // remove member preference for recovery_group notifications - 5/24/22
      // allPreferences.push(["Support Group", "recovery_groups"]);
    } else if (permissions.isOud(user, primaryDiagnosis)) {
      allPreferences.push(["Upcoming Appointments", "appointments"]);
      // remove member preference for recovery_group notifications - 5/24/22
      // allPreferences.push(["Support Group", "recovery_groups"]);
      allPreferences.push(["Drug Testing Hours", "drug_tests"]);
    }

    allPreferences.push(["Chat Messages", "chat_messages"]);
    // allPreferences.push(["Gut Check", "gut_checks"]);
    allPreferences.push(["Special Delivery", "special_deliveries"]);
    allPreferences.push(["Daily Reminder", "daily_reminders"]);

    return allPreferences;
  }

  hasAnySelected(user, notificationType) {
    let { preferenceChanges } = this.state;
    let allPreferences = this.getPreferencesByTier(user);
    let hasSelected = false;
    allPreferences.forEach((pref) => {
      if (preferenceChanges[pref[1]].includes(notificationType)) {
        hasSelected = true;
      }
    });

    return hasSelected;
  }

  renderPreferences() {
    let { user } = this.props;
    let { preferenceChanges } = this.state;
    let allPreferences = this.getPreferencesByTier(user);

    const hasNoEmail = ["daily_reminders"];
    const hasNoText = ["daily_reminders"];
    return allPreferences.map((pref, i) => {
      const field = pref[1];
      const userPref = preferenceChanges[field];
      const email = userPref.includes("email");
      const text = userPref.includes("text");
      const push = userPref.includes("push");

      return (
        <div className="list-item" key={i}>
          <div className="row">
            <p>{pref[0]}</p>
            <div className="col align-center">
              {!hasNoEmail.includes(field) ? (
                <input
                  type="checkbox"
                  checked={email ? true : false}
                  onChange={() => this.handleUpdateField(field, "email")}
                  aria-label={`Email notification for ${pref[0]}`}
                />
              ) : (
                <p>—</p>
              )}
            </div>

            <div className="col align-center">
              {!hasNoText.includes(field) ? (
                <input
                  type="checkbox"
                  checked={text ? true : false}
                  onChange={() => this.handleUpdateField(field, "text")}
                  aria-label={`Text notification for ${pref[0]}`}
                />
              ) : (
                <p>—</p>
              )}
            </div>

            <div className="col align-center">
              <input
                type="checkbox"
                checked={push ? true : false}
                onChange={() => this.handleUpdateField(field, "push")}
                aria-label={`Push notification for ${pref[0]}`}
              />
            </div>
          </div>

          <style jsx>{notificationStyles}</style>
        </div>
      );
    });
  }

  handleUpdateField = (field, notificationType) => {
    let pref = this.state.preferenceChanges;
    let idx = pref[field].findIndex((el) => el === notificationType);
    if (idx > -1) {
      pref[field].splice(idx, 1);
    } else {
      pref[field].push(notificationType);
    }

    if (pref[field].length === 0) {
      pref[field] = ["none"];
    } else {
      idx = pref[field].findIndex((el) => el === "none");
      if (idx > -1) {
        pref[field].splice(idx, 1);
      }
    }

    this.setState({ preferenceChanges: pref });
  };

  handleSelectAll = (notificationType, currentlySelected) => {
    let preferenceChanges = this.state.preferenceChanges;
    const allPreferences = this.getPreferencesByTier(this.props.user);
    allPreferences.forEach((pref) => {
      let idx = preferenceChanges[pref[1]].indexOf(notificationType);
      if (currentlySelected && idx > -1) {
        // if currently selected and needs to be removed
        preferenceChanges[pref[1]].splice(idx, 1);
      }

      if (!currentlySelected && idx < 0) {
        // if not currently selected and needs to be added
        preferenceChanges[pref[1]].push(notificationType);
      }
    });

    this.setState({ preferenceChanges: preferenceChanges });
  };

  handleUpdateTime = (value, field) => {
    let pref = this.state.preferenceChanges;
    let hourInTwelve, meridian;

    if (field === "reminder_hour") {
      hourInTwelve = parseInt(value);
      meridian = this.getMeridian(pref.daily_reminder_hour);
      if (meridian === "AM" && hourInTwelve === 12) {
        hourInTwelve = 0;
      } else if (meridian === "PM" && hourInTwelve < 12) {
        hourInTwelve += 12;
      }
      pref.daily_reminder_hour = hourInTwelve;
    } else if (field === "reminder_meridian") {
      hourInTwelve = this.getHourInTwelveHourFormat(pref.daily_reminder_hour);
      if (value === "AM") {
        pref.daily_reminder_hour =
          pref.daily_reminder_hour < 12
            ? pref.daily_reminder_hour
            : pref.daily_reminder_hour % 12;
      } else if (value === "PM") {
        if (pref.daily_reminder_hour === 0) {
          pref.daily_reminder_hour = 12;
        } else if (pref.daily_reminder_hour !== 12) {
          pref.daily_reminder_hour = (hourInTwelve + 12) % 24;
        }
      }
    }

    this.setState({ preferenceChanges: pref });
  };

  getHourInTwelveHourFormat(hour) {
    let hourInTwelve;
    if (hour === 0) {
      hourInTwelve = 12;
    } else if (hour <= 12) {
      hourInTwelve = hour;
    } else {
      hourInTwelve = hour % 12;
    }

    return hourInTwelve;
  }

  getMeridian(hour) {
    if (hour < 12) {
      return "AM";
    } else {
      return "PM";
    }
  }

  handleSubmit = () => {
    console.log("pref changes on submit", this.state.preferenceChanges);
    this.props.updatePreference(this.profileId, this.state.preferenceChanges);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Notifications)
);
