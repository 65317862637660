import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { DateTime } from "luxon";
import Api from "emr/api";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import { getUrlVars } from "components/utils/helpers/linkHelpers";

import {
  BILLING_SET_STATE,
  NEW_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
} from "emr/constants/actionTypes";
import { fetchReceiptInfo } from "patient_app/api/profileActions";

import permissions from "patient_app/helpers/permissions";
import textHelpers from "patient_app/helpers/textHelpers";
import receiptStyles from "patient_app/stylesheets/payments/receiptStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReceiptInfo: (userId, paymentId, cashPaymentId) =>
      dispatch(fetchReceiptInfo(userId, paymentId, cashPaymentId)),
    newErrors: (status, text) =>
      dispatch({ type: NEW_ERRORS, errors: [{ status: status, text: text }] }),
    newFieldError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    setReduxState: (obj, value) =>
      dispatch({ type: BILLING_SET_STATE, obj: obj, value: value }),
  };
};

const mapStateToProps = (state) => {
  return {
    receiptInfo: state.profile.receiptInfo,
  };
};

class Receipt extends React.Component {
  userId = getUrlVars(window.location.href)["user_id"];
  paymentId = getUrlVars(window.location.href)["payment_id"];
  cashPaymentId = getUrlVars(window.location.href)["cash_payment_id"];

  constructor(props) {
    super(props);
    this.state = {
      payments: null,
    };
  }

  componentDidMount() {
    // this page is used by both admin and user,
    // so it needs a different endpoint to fetch all the info
    this.props.fetchReceiptInfo(
      this.userId,
      this.paymentId,
      this.cashPaymentId
    );
  }

  componentDidUpdate = (prevProps) => {};

  render() {
    let { receiptInfo } = this.props;

    let user, profile, clinic, dto, insuranceInfo, payment;
    if (receiptInfo) {
      user = receiptInfo.user;
      profile = receiptInfo.profile;
      clinic = receiptInfo.clinic;
      dto = receiptInfo.drug_test_order;
      insuranceInfo = receiptInfo.insurance_info;
      payment = receiptInfo.payment;
    }

    let address1 = "3300 Washtenaw Ave, Ste 280";
    let address2 = "Ann Arbor, MI 48104";
    if (clinic && clinic.address) {
      address1 = clinic.address;
      address2 = `${clinic.city}, ${clinic.state} ${clinic.zip_code}`;
    }

    const claimItem = insuranceInfo && insuranceInfo.insurance_claim_item;

    return (
      <div className="Receipts-Container">
        <div className="print-container">
          <button className="custom-button" onClick={() => window.print()}>
            <i className="material-icons">print</i>
            <p>Print</p>
          </button>
        </div>

        <div className="header">
          <img
            src="https://storage.googleapis.com/workit-client-assets/logos/greenLogoWorkit.svg"
            alt="Workit logo"
          />
          <p>Created: {DateTime.local().toFormat("ff ZZZZ")}</p>
        </div>

        <div className="general-info">
          {!receiptInfo && (
            <div className="loading-spinner">
              <img
                src="https://storage.googleapis.com/workit-client-assets/chat/loading.gif"
                alt="Loading"
              />
            </div>
          )}

          <div>
            {clinic && (
              <div className="section">
                <p className="title">Workit Health</p>
                <p>{address1}</p>
                <p>{address2}</p>
                <p>{clinic.phone}</p>
                <p>Tax ID: {clinic.ein}</p>
                {receiptInfo.dos_start && receiptInfo.dos_end && (
                  <p>
                    Dates of Service:{" "}
                    {DateTime.fromISO(receiptInfo.dos_start).toFormat("D")} -{" "}
                    {DateTime.fromISO(receiptInfo.dos_end).toFormat("D")}
                  </p>
                )}
              </div>
            )}

            {insuranceInfo && (
              <div className="section">
                <p className="title">Insurance Information</p>
                {insuranceInfo.billing_code && (
                  <p>Billing Code: {insuranceInfo.billing_code}</p>
                )}
                {insuranceInfo.date_start && (
                  <p>
                    Billing Period:{" "}
                    {DateTime.fromISO(insuranceInfo.date_start).toFormat("D")} -{" "}
                    {DateTime.fromISO(insuranceInfo.date_end).toFormat("D")}
                  </p>
                )}
                <p>Diagnosis: {insuranceInfo.diagnosis_code}</p>
              </div>
            )}
          </div>

          {user && profile && (
            <div className="patient">
              <p className="title">Member</p>
              <p>
                {user.fir_name} {user.las_name}
              </p>
              <p>{user.email}</p>
              <p>DOB: {user.dob}</p>
              <p>{user.phone && textHelpers.formatPhone(user.phone)}</p>
              <p>{profile.address}</p>
              <p>
                {profile.city}, {profile.state} {profile.zip_code}
              </p>
            </div>
          )}
        </div>

        {payment && (
          <table className="payment-table two-column">
            <tbody>
              <tr>
                <th>Payment Method</th>
                <th>
                  {payment.payment_type !== "cash"
                    ? textHelpers.uppercaseFirstLetter(payment.source.funding)
                    : ""}
                </th>
              </tr>

              {payment.payment_type !== "cash" && (
                <tr>
                  <td>
                    {textHelpers.uppercaseFirstLetter(payment.source.brand)}
                  </td>
                  <td>{payment.source.last4}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {dto && (
          <table className="payment-table two-column">
            <tbody>
              <tr>
                <th>Item</th>
                <th>Price</th>
              </tr>

              <tr>
                <td>10-Panel Urine Tests</td>
                <td>${dto.ten_panel.toFixed(2)}</td>
              </tr>

              <tr>
                <td>Saliva Tests</td>
                <td>${dto.oral_fluid_swab.toFixed(2)}</td>
              </tr>

              <tr>
                <td>Shipping ({dto.shipping_method})</td>
                <td>${dto.shipping.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        )}

        {claimItem && (
          <table className="payment-table claim-item">
            <tbody>
              <tr>
                <th>Program</th>
                <th>Billing Period</th>
                <th className="align-right">Billed to Insurance</th>
                <th className="align-right">Paid By Insurance</th>
                <th className="align-right">Remaining Balance</th>
              </tr>

              <tr>
                <td>{payment.description}</td>
                <td>
                  {DateTime.fromISO(insuranceInfo.date_start).toFormat("D")} -{" "}
                  {DateTime.fromISO(insuranceInfo.date_end).toFormat("D")}
                </td>
                <td className="align-right">
                  {claimItem.allowable_amount
                    ? `$${(claimItem.allowable_amount / 100).toFixed(2)}`
                    : ""}
                </td>
                <td className="align-right">
                  {claimItem.paid_amount
                    ? `$${(claimItem.paid_amount / 100).toFixed(2)}`
                    : "$0.00"}
                </td>
                <td className="align-right">
                  ${(payment.amount / 100).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {payment && !(dto || claimItem) && (
          <table className="payment-table two-column">
            <tbody>
              <tr>
                <th>Item</th>
                <th>Price</th>
              </tr>

              <tr>
                <td>{payment.description}</td>
                <td>${(payment.amount / 100).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        )}

        <style jsx>{receiptStyles}</style>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Receipt);
