import React from "react";
import { DateTime } from "luxon";
import Api from "patient_app/api";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { fetchProfile } from "patient_app/api/commonActions";
import { fetchActionItems } from "patient_app/api/pendingItemActions";
import { skipPendingItem } from "patient_app/api/pendingItemActions";
import { fetchZoomEvents } from "patient_app/api/zoomEventActions";
import {
  OPEN_CHAT,
  APPTS_SHOW_EXPANDED,
} from "patient_app/constants/actionTypes";

import { getIconUrl } from "patient_app/helpers/linkHelpers";
import permissions from "patient_app/helpers/permissions";
import appointmentHelpers from "patient_app/helpers/appointmentHelpers";
import time from "patient_app/helpers/time";
import dashboardBlockStyles from "patient_app/stylesheets/appointments/dashboardBlockStyles";
import { variables } from "patient_app/stylesheets/variables";
import assets from "patient_app/assets";
import Group from "patient_app/assets/images/group.png";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProfile: () => dispatch(fetchProfile()),
    openChat: (data) => dispatch({ type: OPEN_CHAT, data: data }),
    expandAppointment: (appt) =>
      dispatch({ type: APPTS_SHOW_EXPANDED, appt: appt }),
    fetchZoomEvents: () => dispatch(fetchZoomEvents()),
    fetchActionItems: (past, userId) =>
      dispatch(fetchActionItems(past, userId)),
    skipPendingItem: (title, uid) => dispatch(skipPendingItem(title, uid)),
  };
};

const mapStateToProps = (state) => {
  return {
    actionItems: state.appointments.actionItems,
    appointments: state.appointments.appointments,
    counselor: state.common.counselor,
    clinic: state.common.clinic,
    completedAppointments: state.appointments.completedAppointments,
    gtcId: state.appointments.gtcId,
    mymId: state.appointments.mymId,
    primaryDiagnosis: state.common.primaryDiagnosis,
    profile: state.common.profile,
    user: state.common.user,
    userRecoveryGroups: state.appointments.userRecoveryGroups,
    zoomEvents: state.appointments.zoomEvents,
    zoomParticipants: state.appointments.zoomParticipants,
  };
};

class ActionItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "current",
    };
  }

  componentDidMount() {
    if (!this.props.profile) {
      this.props.fetchProfile();
    }

    if (!this.props.zoomEvents) {
      this.props.fetchZoomEvents();
    }
  }

  render() {
    let {
      appointments,
      completedAppointments,
      user,
      zoomEvents,
      zoomParticipants,
    } = this.props;
    let { view } = this.state;

    return (
      <div className="dashboard-block">
        <div className="action-item-selector">
          <button
            className={`custom-button ${view === "current" ? "selected" : ""}`}
            onClick={() => this.handleSelect("current")}
          >
            Current Items
          </button>
          <button
            className={`custom-button ${
              view === "completed" ? "selected" : ""
            }`}
            onClick={() => this.handleSelect("completed")}
          >
            Completed Items
          </button>
        </div>

        {this.renderItems()}

        {view === "current" &&
          (permissions.isClinic(user) || permissions.isCounselor(user)) &&
          !appointments?.length && (
            <button
              className="custom-button block-item"
              onClick={this.handleScheduleAppt}
            >
              <img
                className="add-icon"
                src={getIconUrl("plus-round", "dark-blue")}
              />

              <div className="details">
                <p className="title">Nothing scheduled</p>
                <p>Schedule an appointment</p>
              </div>
            </button>
          )}
      </div>
    );
  }

  handleSelect = (view) => {
    this.setState({ view: view });
    const completed = view === "completed";
    this.props.fetchActionItems(completed, this.props.user.id);
  };

  renderItems = () => {
    const { view } = this.state;
    const {
      actionItems,
      appointments,
      completedAppointments,
      user,
      zoomEvents,
      zoomParticipants,
    } = this.props;

    const shownAppts =
      view === "current" ? appointments : completedAppointments;

    const shownZoomEvents = this.formatZoomEvents(view);
    if (!actionItems[view] || !shownAppts) {
      return (
        <div className="block-item">
          <p>Loading...</p>
          <style jsx>{dashboardBlockStyles}</style>
        </div>
      );
    }

    const sortedItems = [
      ...actionItems[view],
      ...shownAppts,
      //...shownZoomEvents,
    ].sort(this.sortComparator);
    if (!sortedItems.length) {
      return (
        <div className="block-item">
          <p>No {view} items.</p>
          <style jsx>{dashboardBlockStyles}</style>
        </div>
      );
    }

    return sortedItems.map((item, i) => {
      if (item.category === "todo") {
        return this.renderPendingItem(item, i);
      } else {
        return this.renderAppointment(item, i);
      }
    });
  };

  formatZoomEvents = (view) => {
    const { zoomEvents } = this.props;
    let arr = [];
    const now = DateTime.local().startOf("day");
    zoomEvents?.map((ze, i) => {
      const ts = DateTime.fromISO(ze.start_at);
      if (this.state.view === "current") {
        if (ts >= now) {
          arr.push(ze);
        }
      } else {
        if (ts < now) {
          arr.push(ze);
        }
      }
    });

    return arr;
  };

  /*
    If compareFunction(a, b) returns less than 0, leave a and b unchanged.
    If compareFunction(a, b) returns greater than 0, sort b before a.

    Later dates should be sorted earlier for view === "completed",
    and sorted later for view === "current"
  */
  sortComparator = (a, b) => {
    const view = this.state.view;
    let dateA, dateB;
    if (a.category === "todo") {
      // a is a PendingItem
      dateA = a.approved ? a.approved_at : a.event?.start_at || a.due_date;
    } else {
      // a is a CalEvent
      dateA = a.start_at_calculated || a.start_at;
    }

    if (b.category === "todo") {
      // b is a PendingItem
      dateB = b.approved ? b.approved_at : b.event?.start_at || b.due_date;
    } else {
      // b is a CalEvent
      dateB = b.start_at_calculated || b.start_at;
    }

    if (!dateA) {
      return 1;
    } else if (!dateB) {
      return -1;
    } else {
      if (DateTime.fromISO(dateA) < DateTime.fromISO(dateB)) {
        // dateA is earlier than dateB
        return view === "current" ? -1 : 1;
      } else {
        // dateA is later than or equal to dateB
        return view === "current" ? 1 : -1;
      }
    }

    return 1; // keep things the same
  };

  renderPendingItem(item, key) {
    const {
      clinic,
      profile,
      user,
      userRecoveryGroups,
      zoomEvents,
      zoomParticipants,
    } = this.props;
    const { view } = this.state;

    const urls = {
      address_todo: "/onboarding/address?redirect=todo",
      emergency_contact_todo: profile
        ? `/profiles/${profile.id}/account?redirect=todo&tab=emergency_contact`
        : "",
      gtc_todo: "#", // handleClickPendingItem() handles going to GTC
      insurance_todo: profile
        ? `/profiles/${profile.id}/insurance?redirect=todo`
        : "",
      mym_todo: "#", // handleClickPendingItem() handles going to Mapping My Mission
      treatment_plan_todo: "#", // handleClickPendingItem() handles going to Treatment Goals
      payment_todo: "/payments/purchase?redirect=todo",
      upcoming_payment_todo: profile
        ? `/profiles/${profile.id}/payment?redirect=todo`
        : "",
      create_roi_todo: profile ? `https://www.workithealth.com/roi/` : "",
      renew_roi_todo: profile ? `https://www.workithealth.com/roi/` : "",
      approve_treatment_plan: `/treatment_plans/${item.model_id}`,
      product_orientation: profile
        ? `/profiles/${profile.id}/documents?tab=general_documents&clinic_file_id=${item.model_id}`
        : "",
      vdt_request: clinic?.drug_test_url,
    };

    const today = DateTime.local();
    const dueDateTime = item.event?.start_at || item.due_date;
    const due = DateTime.fromISO(dueDateTime).setZone(today.zone);
    const dueToday =
      !item.approved &&
      due.toFormat("LL/dd/yyyy") === today.toFormat("LL/dd/yyyy");
    const overdue = !item.approved && due.endOf("day") < today;
    let startEndTime = "";
    let title = item.title;
    const typeUpcomingRecovery = item.uid === "upcoming_recovery_group";
    let typeSMA = false;
    let group = null;
    if (userRecoveryGroups && typeUpcomingRecovery && zoomParticipants) {
      group = userRecoveryGroups.find((g) => g.id == item.model_id);
      if (!group) return null;
      title = group?.title_member_facing;
      typeSMA = group?.category == "sma";
      const times =
        group?.startAt && this.calculateRecoveryGroupTime(group, item);

      startEndTime =
        times &&
        times.startAt &&
        times.endAt &&
        `${times.startAt.toFormat("h:mm a")}-${times.endAt.toFormat(
          "h:mm a ZZZZ"
        )}`;
    }

    let url = urls[item.uid]; // if url is blank, assume it is a course

    // if uid starts with "upload_", redirect to upload page
    if (item.uid.includes("upload_")) {
      url = `/pending_items/${item.id}/upload`;
    }

    return (
      <a
        className={`block-item ${view === "completed" ? "disabled" : ""} ${
          typeSMA ? "sma" : ""
        }`}
        href={url || "#"}
        onClick={(e) => this.handleClickPendingItem(e, item, typeSMA)}
        key={key}
        target={url === "https://www.workithealth.com/roi/" ? "blank" : ""}
      >
        {typeUpcomingRecovery &&
          (typeSMA ? (
            <div className="icon-wrapper">
              <img src={getIconUrl("calendar", "white")} />
            </div>
          ) : (
            <img
              src={group?.file?.url || assets.darkGreenGradient}
              alt="group-icon"
              className="group-thumbnail"
            />
          ))}
        <div className={`details ${overdue ? "overdue" : ""}`}>
          <p className="title">{title}</p>
          {view === "current" && (
            <>
              <p>
                {!typeUpcomingRecovery && "Due:"}{" "}
                {!!dueDateTime
                  ? DateTime.fromISO(dueDateTime).toFormat("LLL dd, yyyy")
                  : "N/A"}
              </p>
              <p>{startEndTime}</p>
            </>
          )}
          {view === "completed" && (
            <>
              <p>
                Completed:{" "}
                {item.approved_at
                  ? DateTime.fromISO(item.approved_at).toFormat("LLL dd, yyyy")
                  : ""}
              </p>
              <p>{startEndTime}</p>
            </>
          )}
        </div>

        {dueToday && (
          <img
            src={getIconUrl("urgent-round", "brand-green")}
            className="alert-icon"
          />
        )}
        {overdue && (
          <img src={getIconUrl("urgent-round", "red")} className="alert-icon" />
        )}
        {view === "current" && (
          <img
            src={getIconUrl("chevron-right", typeSMA ? "white" : "dark-blue")}
            // src={getIconUrl("chevron-right", "white")}
          />
        )}
        <style jsx>{dashboardBlockStyles}</style>
      </a>
    );
  }

  renderAppointment = (appt, key) => {
    let { profile } = this.props;
    const { view } = this.state;

    const title = appointmentHelpers.getTitle(appt);
    const type = appt.category === "recovery_group" ? "Group" : "Appt";
    const tz = time.getTimeZone(profile);
    const startAt = appointmentHelpers.calculateStartAt(appt, tz);
    const endAt = appointmentHelpers.calculateEndAt(appt, tz);
    const color = appointmentHelpers.getApptColor(appt);

    let startEndTime = "";
    if (!!startAt && !!endAt) {
      startEndTime = `${startAt.toFormat("h:mm a")}-${endAt.toFormat(
        "h:mm a ZZZZ"
      )}`;
    }

    if (view === "completed") {
      return (
        <div data-id={appt.id} className="block-item disabled appt" key={key}>
          <div className="details">
            <p className="title no-margin">{title}</p>
            <p>{startAt?.toFormat("LLL dd, yyyy")}</p>
            <p>{startEndTime}</p>
          </div>
          <style jsx>{dashboardBlockStyles}</style>
        </div>
      );
    } else {
      return (
        <button
          data-id={appt.id}
          className="custom-button block-item appt"
          key={key}
          onClick={() => this.props.expandAppointment(appt)}
          style={{ backgroundColor: color }}
        >
          <div className="icon-wrapper">
            <img src={getIconUrl("calendar", "white")} />
          </div>
          <div className="details">
            <p className="title no-margin">{title}</p>
            <p>{startAt?.toFormat("LLL dd, yyyy")}</p>
            <p>{startEndTime}</p>
          </div>

          <img src={getIconUrl("chevron-right", "white")} />

          <style jsx>{dashboardBlockStyles}</style>
        </button>
      );
    }
  };

  handleClickPendingItem = async (e, item, typeSMA = false) => {
    // courses need to be handled a specific way
    const {
      gtcId,
      mymId,
      primaryDiagnosis,
      user,
      profile,
      userRecoveryGroups,
    } = this.props;
    if (
      ["gtc_todo", "mym_todo", "treatment_plan_todo"].includes(item.uid) ||
      item.uid.startsWith("exercise_")
    ) {
      if (e) {
        e.preventDefault();
      }
      let exerciseId = null;
      if (item.uid === "gtc_todo") {
        exerciseId = permissions.isOud(user, primaryDiagnosis) ? "540" : "1740";
      } else if (item.uid === "mym_todo") {
        exerciseId = "1108";
      } else if (item.uid === "treatment_plan_todo") {
        exerciseId = "1818";
      } else if (item.uid.startsWith("exercise_")) {
        exerciseId = item.uid.replace(/\D+/g, "");
      }

      if (exerciseId) {
        let path = `/exercise_responses/create?exercise=${exerciseId}`;
        if (item.uid === "gtc_todo" && gtcId) {
          path += `&item_id=${gtcId}`;
        } else if (item.uid === "mym_todo" && mymId) {
          path += `&item_id=${mymId}`;
        } else if (item.uid.startsWith("exercise_")) {
          path += `&pending_item_id=${item.id}`;
        }

        const data = {
          url: path,
          data: { method: "POST" },
        };

        try {
          const res = await Api.makeRequest(data);
          if (res.path) {
            window.location.href = res.path;
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
    // certain items should be completed upon viewing
    else if (
      [
        "upcoming_payment_todo",
        "product_orientation",
        "create_roi_todo",
      ].includes(item.uid)
    ) {
      await this.props.skipPendingItem("", item.uid);
      // TODO: refresh user's browser by updating the list of pending items
    } else if (["upcoming_recovery_group"].includes(item.uid)) {
      const group = userRecoveryGroups.find((g) => g.id == item.model_id);
      if (group) {
        const { sa_h, sa_m, ea_h, ea_m, time_zone } = group;

        let times = this.calculateRecoveryGroupTime(group, item);
        const category = typeSMA ? "sma" : "recovery_group";

        let event = item.event || {};

        this.props.expandAppointment({
          ...group,
          ...event,
          category,
          start_at: times.startAt,
          end_at: times.endAt,
          item: item,
        });
      }
    }
  };

  calculateRecoveryGroupTime = (group, item) => {
    const { clinic, profile, user, zoomEvents, zoomParticipants } = this.props;
    if (!zoomParticipants) {
      return;
    }

    let times = {};
    let startAt;
    let endAt;

    if (group.use_zoom_events) {
      const part = zoomParticipants?.find(
        (p) => p.id === item.zoom_participant_id
      );
      const event = zoomEvents?.find((z) => z.id === part?.zoom_event_id);
      if (event) {
        startAt = DateTime.fromISO(event.start_at).setZone(profile.time_zone);
        endAt = DateTime.fromISO(event.end_at).setZone(profile.time_zone);
      }
    } else {
      const { sa_h, sa_m, ea_h, ea_m, time_zone } = group;

      startAt = DateTime.local()
        .setZone(time_zone) // set to recovery group tz first
        .set({ hour: sa_h, minute: sa_m }); // set hour and minute
      endAt = DateTime.local()
        .setZone(time_zone) // set to recovery group tz first
        .set({ hour: ea_h, minute: ea_m }); // set hour and minute

      if (profile) {
        // set back to user tz if possible
        startAt = startAt.setZone(profile.time_zone);
        endAt = endAt.setZone(profile.time_zone);
      }
    }

    times.startAt = startAt;
    times.endAt = endAt;

    return times;
  };

  handleScheduleAppt = () => {
    const { counselor, profile, user } = this.props;
    const message = `Hi, I'd like to schedule an appointment.`;
    if (permissions.isClinic(user)) {
      this.props.openChat({
        view: "index",
        prepopulatedMessage: message,
        topic: "ADMIN",
      });
    } else if (permissions.isCounselor(user)) {
      if (permissions.isEnterprise(profile)) {
        this.props.history.push("/onboarding/schedule?referral=dashboard");
      } else {
        this.props.openChat({
          view: "index",
          prepopulatedMessage: message,
          adminId: counselor?.id,
        });
      }
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionItems)
);
