//alcohol checks
export const ALCOHOL_CHECK_QUESTIONS_LOAD = "ALCOHOL_CHECK_QUESTIONS_LOAD";

//appointments
export const APPTS_UPDATE_CONTENT_LOADED = "APPTS_UPDATE_CONTENT_LOADED";
export const APPTS_LOAD = "APPTS_LOAD";
export const APPTS_LOAD_RECOVERY_GROUP = "APPTS_LOAD_RECOVERY_GROUP";
export const APPTS_LOAD_MORE = "APPTS_LOAD_MORE";
export const APPTS_LOAD_NEXT_COURSE = "APPTS_LOAD_NEXT_COURSE";
export const APPTS_TOGGLE_BOOKING_LOADING = "APPTS_TOGGLE_BOOKING_LOADING";
export const APPTS_BOOKING_LOAD = "APPTS_BOOKING_LOAD";
export const APPTS_SHOW_EXPANDED = "APPTS_SHOW_EXPANDED";
export const APPTS_DRUG_TEST_DETAILS = "APPTS_DRUG_TEST_DETAILS";
export const APPTS_ACTION_ITEMS = "APPTS_ACTION_ITEMS";
export const APPTS_USER_RECOVERY_GROUP = "APPTS_USER_RECOVERY_GROUP";
export const ZOOM_EVENTS_LOAD = "ZOOM_EVENTS_LOAD";
export const ZOOM_PARTICIPANTS_LOAD = "ZOOM_PARTICIPANTS_LOAD";

//chat
export const UPDATE_UNREAD_COUNT = "UPDATE_UNREAD_COUNT";
export const UPDATE_SHOW_CHAT = "UPDATE_SHOW_CHAT";
export const CHATS_LOAD = "CHATS_LOAD";
export const OPEN_CHAT = "OPEN_CHAT";
export const CHAT_UPDATE_STATE = "CHAT_UPDATE_STATE";

//clinic
export const CLINICS_LOAD = "CLINICS_LOAD";
export const CLINIC_LOAD = "CLINIC_LOAD";
export const CLINIC_LOAD_HOLIDAY = "CLINIC_LOAD_HOLIDAY";

//common
export const AVAILABILITIES_LOAD = "AVAILABILITIES_LOAD";
export const USER_LOAD = "USER_LOAD";
export const PROFILE_LOAD = "PROFILE_LOAD";
export const PAGE_LOAD = "PAGE_LOAD";
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
export const NEW_ERRORS = "NEW_ERRORS";
export const NEW_FORM_ERRORS = "NEW_FORM_ERRORS";
export const NEW_FIELD_SPECIFIC_ERROR = "NEW_FIELD_SPECIFIC_ERROR";
export const CLEAR_FIELD_SPECIFIC_ERRORS = "CLEAR_FIELD_SPECIFIC_ERRORS";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const USER_UPDATE_LOCAL = "USER_UPDATE_LOCAL";
export const PROFILE_UPDATE_LOCAL = "PROFILE_UPDATE_LOCAL";
export const COACH_LOAD = "COACH_LOAD";
export const CARE_PROVIDER_LOAD = "CARE_PROVIDER_LOAD";
export const COMMON_UPDATE_START = "COMMON_UPDATE_START";
export const COMMON_UPDATE_SUCCESS = "COMMON_UPDATE_SUCCESS";
export const COMMON_UPDATE_FAILURE = "COMMON_UPDATE_FAILURE";
export const COMMON_UPDATE_STATE = "COMMON_UPDATE_STATE";
export const TOGGLE_PAYWALL = "TOGGLE_PAYWALL";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const UPDATE_USER_SIGNED_IN = "UPDATE_USER_SIGNED_IN";
export const DECLARE_NO_COACH = "DECLARE_NO_COACH";

//curriculum
export const CURRICULUM_LOAD = "CURRICULUM_LOAD";
export const CURRICULUM_LOAD_SECTION = "CURRICULUM_LOAD_SECTION";
export const CURRICULUM_TOGGLE_SKIP_LOADING = "CURRICULUM_TOGGLE_SKIP_LOADING";
export const CURRICULUM_UPDATE_SELECTED_ITEM =
  "CURRICULUM_UPDATE_SELECTED_ITEM";
export const CURRICULUM_LOAD_SPECIAL_DELIVERY =
  "CURRICULUM_LOAD_SPECIAL_DELIVERY";
export const CURRICULUM_SKIPPED = "CURRICULUM_SKIPPED";

//session, reset password, mfa
export const SESSION_SET_LOCAL_STATE = "SESSION_SET_LOCAL_STATE";
export const SESSION_USERID_UPDATE = "SESSION_USERID_UPDATE";
export const SESSION_EMAIL_UPDATE = "SESSION_EMAIL_UPDATE";
export const SESSION_UNIQUE_PHONE_UPDATE = "SESSION_UNIQUE_PHONE_UPDATE";
export const SESSION_PASSWORD_UPDATE = "SESSION_PASSWORD_UPDATE";
export const SESSION_TOGGLE_SHOW_SIGNUP = "SESSION_TOGGLE_SHOW_SIGNUP";
export const UPDATE_SET_PASSWORD = "UPDATE_SET_PASSWORD";
export const SESSION_QUESTIONNAIRE_DUE = "SESSION_QUESTIONNAIRE_DUE";
export const SESSION_HAS_PENDING_ITEM = "SESSION_HAS_PENDING_ITEM";
export const SESSION_LOAD_OTP_CONFIG = "SESSION_LOAD_PROVISIONING_URI";
export const UPDATE_MFA_CODE = "UPDATE_MFA_CODE";
export const SESSION_LOAD_CHECKLIST = "SESSION_LOAD_CHECKLIST";
export const SESSION_VERIFY_EMAIL = "SESSION_VERIFY_EMAIL";
export const SESSION_ACTIVATE_CODE = "SESSION_ACTIVATE_CODE";
export const SESSION_ACTIVATION_PAGE_LOAD = "SESSION_ACTIVATION_PAGE_LOAD";

export const PAYMENT_LOAD = "PAYMENT_LOAD";
export const FETCH_PROVIDER_AVS = "FETCH_PROVIDER_AVS";
export const FETCH_STAFF_AVAILABILITIES = "FETCH_STAFF_AVAILABILITIES";
export const UPDATE_APPOINTMENT_LOCAL = "UPDATE_APPOINTMENT_LOCAL";
export const LOAD_FINALIZED_APPOINTMENT = "LOAD_FINALIZED_APPOINTMENT";
export const ONBOARDING_REQUEST_START = "CREATE_APPOINTMENT_START";
export const ONBOARDING_REQUEST_SUCCESS = "CREATE_APPOINTMENT_SUCCESS";
export const ONBOARDING_REQUEST_FAILURE = "CREATE_APPOINTMENT_FAILURE";
export const ONBOARDING_USER_LOCATION = "ONBOARDING_USER_LOCATION";
export const UPDATE_ONBOARDING_PAYERS = "UPDATE_ONBOARDING_PAYERS";
export const UPDATE_PAYMENT_OPTIONS = "UPDATE_PAYMENT_OPTIONS";
export const TOGGLE_ONBOARDING_LOADING = "TOGGLE_ONBOARDING_LOADING";

export const ONBOARDING_HEALTH_INFO = "ONBOARDING_HEALTH_INFO";
export const UPDATE_AUD_QUESTIONNAIRE_QUESTION_LOCAL =
  "UPDATE_AUD_QUESTIONNAIRE_QUESTION_LOCAL";
export const UPDATE_ENT_QUESTIONNAIRE_QUESTION_LOCAL =
  "UPDATE_ENT_QUESTIONNAIRE_QUESTION_LOCAL";
export const SET_NEXT_QUESTIONNAIRE_QUESTION =
  "SET_NEXT_QUESTIONNAIRE_QUESTION";
export const ONBOARDING_PAGE_LOAD = "ONBOARDING_PAGE_LOAD";
export const ONBOARDING_SET_USER = "ONBOARDING_SET_USER";
export const ONBOARDING_CALENDAR_LINKS = "ONBOARDING_CALENDAR_LINKS";
export const ONBOARDING_VALID_EMPLOYER = "ONBOARDING_VALID_EMPLOYER";
export const ONBOARDING_NEEDS_VERIFICATION = "ONBOARDING_NEEDS_VERIFICATION";
export const ONBOARDING_RESET_SCHEDULE_VARS = "ONBOARDING_RESET_SCHEDULE_VARS";
export const ONBOARDING_FETCH_INDUCTION_BLOCKS =
  "ONBOARDING_FETCH_INDUCTION_BLOCKS";
export const ONBOARDING_UPDATE_PROGRESS = "ONBOARDING_UPDATE_PROGRESS";
export const ONBOARDING_VERIFY_ADDRESS = "ONBOARDING_VERIFY_ADDRESS";
export const ONBOARDING_UPDATE_STATE = "ONBOARDING_UPDATE_STATE";

//profile
export const PROFILE_REQ_START = "PROFILE_REQ_START";
export const PROFILE_REQ_SUCCESS = "PROFILE_REQ_SUCCESS";
export const PROFILE_REQ_FAILURE = "PROFILE_REQ_FAILURE";
export const FETCH_PROGRAM = "FETCH_PROGRAM";
export const FETCH_PAYMENT_INITIAL = "FETCH_PAYMENT_INITIAL";
export const FETCH_PAYMENT_ALL = "FETCH_PAYMENT_ALL";
export const FETCH_MISSION_MAP = "FETCH_MISSION_MAP";
export const PROFILE_UPDATE_STATE_LOCAL = "PROFILE_UPDATE_STATE_LOCAL";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const PROFILE_UPDATING_PICTURE = "PROFILE_UPDATING_PICTURE";
export const PROFILE_LOAD_DOCUMENTS = "PROFILE_LOAD_DOCUMENTS";
export const PROFILE_LOAD_MORE_FROM_CHAT = "PROFILE_LOAD_MORE_FROM_CHAT";
export const PROFILE_LOAD_MORE_SIGNED_DOCS = "PROFILE_LOAD_MORE_SIGNED_DOCS";
export const PROFILE_LOAD_RECEIPT_INFO = "PROFILE_LOAD_RECEIPT_INFO";
export const PROFILE_MAIN_TAB = "PROFILE_MAIN_TAB";
export const PROFILE_LOAD_PATIENT_INVOICE = "PROFILE_LOAD_PATIENT_INVOICE";
export const PROFILE_REQ_CODE_SUCCESS = "PROFILE_REQ_CODE_SUCCESS";
export const PROFILE_REQ_CODE_RESET = "PROFILE_REQ_CODE_RESET";
export const PROFILE_UPDATE_EMAIL_SUCCESS = "PROFILE_UPDATE_EMAIL_SUCCESS";
export const PROFILE_UPDATE_EMAIL_ERRORS = "PROFILE_UPDATE_EMAIL_ERRORS";
export const PROFILE_UPDATE_PHONE_SUCCESS = "PROFILE_UPDATE_PHONE_SUCCESS";
export const PROFILE_UPDATE_PHONE_ERRORS = "PROFILE_UPDATE_PHONE_ERRORS";

//insurance
export const INSURANCE_REQ_START = "INSURANCE_REQ_START";
export const INSURANCE_REQ_SUCCESS = "INSURANCE_REQ_SUCCESS";
export const INSURANCE_REQ_FAILURE = "INSURANCE_REQ_FAILURE";
export const INSURANCE_LOADING = "INSURANCE_LOADING";
export const INSURANCE_UPDATE_STATE = "INSURANCE_UPDATE_STATE";
export const INSURANCE_UPDATE_COVERAGE = "INSURANCE_UPDATE_COVERAGE";
export const INSURANCE_PAGE_LOAD = "INSURANCE_PAGE_LOAD";
export const UPDATE_INSURANCE_DETAILS_LOCAL = "UPDATE_INSURANCE_DETAILS_LOCAL";
export const INSURANCE_UPDATE_ELIGIBLE_COVERAGE =
  "INSURANCE_UPDATE_ELIGIBLE_COVERAGE";
export const TOGGLE_COVERAGE_CHECK_IN_PROGRESS =
  "TOGGLE_COVERAGE_CHECK_IN_PROGRESS";
export const UPDATE_PATIENT_PAYMENT = "UPDATE_PATIENT_PAYMENT";
export const INSURANCE_INFO_LOAD = "INSURANCE_INFO_LOAD";
export const INSURANCE_CLEAR_COVERAGE_INFO = "INSURANCE_CLEAR_COVERAGE_INFO";

//pendingItem
export const PENDING_ITEM_START = "PENDING_ITEM_START";
export const PENDING_ITEM_SUCCESS = "PENDING_ITEM_SUCCESS";
export const PENDING_ITEM_FAILURE = "PENDING_ITEM_FAILURE";
export const PENDING_ITEM_LOAD = "PENDING_ITEM_LOAD";
export const PENDING_ITEM_TOGGLE_SIGNED = "PENDING_ITEM_TOGGLE_SIGNED";
export const PENDING_ITEM_UPDATE_SIGNATURE = "PENDING_ITEM_UPDATE_SIGNATURE";
export const PENDING_ITEM_START_TALKING = "PENDING_ITEM_START_TALKING";

//video
export const SET_LOCAL_VIDEO_REF = "SET_LOCAL_VIDEO_REF";
export const SET_REMOTE_VIDEO_REF = "SET_REMOTE_VIDEO_REF";
export const SET_REMOTE_AUDIO_REF = "SET_REMOTE_AUDIO_REF";
export const VR_REQ_START = "VR_REQ_START";
export const VR_REQ_SUCCESS = "VR_REQ_SUCCESS";
export const VR_REQ_FAILURE = "VR_REQ_FAILURE";
export const VR_LOAD = "VR_LOAD";
export const SET_TWILIO_ROOM_OBJ = "SET_TWILIO_ROOM_OBJ";
export const CLEAR_VIDEO_ROOM = "CLEAR_VIDEO_ROOM";
export const VIDEO_TOGGLE_VIDEO = "VIDEO_TOGGLE_VIDEO";
export const VIDEO_TOGGLE_AUDIO = "VIDEO_TOGGLE_AUDIO";
export const VIDEO_TOGGLE_REMOTE_AUDIO = "VIDEO_TOGGLE_REMOTE_AUDIO";
export const VIDEO_TOGGLE_REMOTE_VIDEO = "VIDEO_TOGGLE_REMOTE_VIDEO";
export const VIDEO_IS_PREVIOUS = "VIDEO_IS_PREVIOUS";

//videoCall
export const VC_INITIAL_REQUEST_FAILED = "VC_INITIAL_REQUEST_FAILED";
export const VC_TOKEN_LOAD = "VC_TOKEN_LOAD";
export const VC_ZOOM_EVENT_ID_LOAD = "VC_ZOOM_EVENT_ID_LOAD";
export const VC_SET_ERROR = "VC_SET_ERROR";
export const VC_SET_MEDIA_PERMISSIONS_ALLOWED =
  "VC_SET_MEDIA_PERMISSIONS_ALLOWED";
export const VC_SET_MEDIA_PERMISSIONS_BLOCKED =
  "VC_SET_MEDIA_PERMISSIONS_BLOCKED";
export const VC_UPDATE_STATE = "VC_UPDATE_STATE";
export const VC_SET_PARTICIPANT = "VC_SET_PARTICIPANT";
export const VC_REMOVE_ROOM = "VC_REMOVE_ROOM";
export const VC_SET_REMOTE_TRACK = "VC_SET_REMOTE_TRACK";
export const VC_TOGGLE_CHAT = "VC_TOGGLE_CHAT";
export const VRQ_USES_ZOOM = "VRQ_USES_ZOOM";
export const ZOOM_EVENTS_LOAD_PARTICIPANT = "ZOOM_EVENTS_LOAD_PARTICIPANT";

//randomDrugTest
export const RDT_REQ_START = "RDT_REQ_START";
export const RDT_REQ_SUCCESS = "RDT_REQ_SUCCESS";
export const RDT_REQ_FAILURE = "RDT_REQ_FAILURE";
export const RDT_LOAD = "RDT_LOAD";

//drugTestOrder
export const DTO_PAGE_LOAD = "DTO_PAGE_LOAD";
export const DTO_START = "DTO_START";
export const DTO_SUCCESS = "DTO_SUCCESS";
export const DTO_FAILURE = "DTO_FAILURE";
export const DTO_LOAD_ALL = "DTO_LOAD_ALL";
export const DTO_LOAD_MORE = "DTO_LOAD_MORE";
export const DTO_LOAD_ONE = "DTO_LOAD_ONE";
export const DTO_UPDATE_STATE = "DTO_UPDATE_STATE";
export const DTO_CLEAR_STATE = "DTO_CLEAR_STATE";

//emergencyContact
export const EC_REQ_START = "EC_REQ_START";
export const EC_REQ_SUCCESS = "EC_REQ_SUCCESS";
export const EC_REQ_FAILURE = "EC_REQ_FAILURE";
export const EC_LOAD = "EC_LOAD";

// gtc
export const GTC_VIEW = "GTC_VIEW";
export const GTC_REQ_START = "GTC_REQ_START";
export const GTC_REQ_SUCCESS = "GTC_REQ_SUCCESS";
export const GTC_REQ_FAILURE = "GTC_REQ_FAILURE";
export const GTC_LOAD_STATE = "GTC_LOAD_STATE";

// treatmentPlan
export const TX_PLAN_LOAD = "TX_PLAN_LOAD";
export const TX_PLAN_USER_PLANS = "TX_PLAN_USER_PLANS";
