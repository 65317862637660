import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import {
  UPDATE_SET_PASSWORD,
  NEW_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
  NEW_FORM_ERRORS,
} from "patient_app/constants/actionTypes";

import FormErrors from "patient_app/components/utils/FormErrors";

import { createPassword } from "patient_app/api/sessionActions";

import onboardingAssets from "patient_app/assets/onboarding";

import MainLayout from "patient_app/components/layouts/MainLayout";
import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import PasswordField from "patient_app/components/input_fields/PasswordField";
import PasswordConfField from "patient_app/components/input_fields/PasswordConfField";
import SubmitButton from "patient_app/components/buttons/SubmitButton";

import validation from "patient_app/helpers/validation";
import { getUrlVars } from "patient_app/helpers/linkHelpers";

const mapDispatchToProps = (dispatch) => {
  return {
    onErrors: (errors) => dispatch({ type: NEW_FORM_ERRORS, errors: errors }),
    updateSetPasswordLocal: (setPassword) =>
      dispatch({ type: UPDATE_SET_PASSWORD, setPassword: setPassword }),
    onError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    clearErrors: () => dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    createPassword: (setPassword, token) =>
      dispatch(createPassword(setPassword, token)),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.common.loading,
    errors: state.common.errors,
    setPassword: state.session.setPassword,
  };
};

class Edit extends React.Component {
  componentDidMount() {
    document.title = "Change Password | Workit Health";
  }

  render() {
    let { loading, errors, setPassword } = this.props;

    let showPasswordStrength = false;
    let passwordStrength;
    let passwordDetails;

    if (setPassword.password && setPassword.password.length > 5) {
      showPasswordStrength = true;
      passwordStrength = validation.passwordStrength(setPassword.password);
      passwordDetails = validation.passwordDetails(passwordStrength);
    }

    return (
      <MainLayout className="Page-Password-Reset">
        <ContentMain>
          <ContentBox className="onboarding password_reset">
            <div className="inputs social">
              <h1>Create a Password</h1>

              <FormErrors />

              <PasswordField
                field={"password"}
                title={"New Password"}
                value={setPassword.password}
                id={"password"}
                onUpdateField={this.handleUpdateField}
                onKeyPress={this.onKeyPressPassword}
                showPasswordStrength={showPasswordStrength}
                passwordDetails={passwordDetails}
                uid={"password"}
                required={true}
              />

              <PasswordConfField
                field={"password_conf"}
                title={"Confirm New Password"}
                value={setPassword.password_conf}
                id={"password-conf"}
                onUpdateField={this.handleUpdateField}
                onKeyPress={this.onKeyPress}
                uid={"password_conf"}
                required={true}
              />

              <SubmitButton
                text={"Continue"}
                loading={loading}
                onSubmit={this.handleSubmit}
              />
            </div>
          </ContentBox>
        </ContentMain>
      </MainLayout>
    );
  }

  onKeyPress = (e) => {
    if (e.keyCode == 13) {
      this.handleSubmit();
    }
  };

  onKeyPressPassword = (e) => {
    if (e.keyCode == 13) {
      this.handleSubmit();
    } else {
      validation.validate(this.props.setPassword);
    }
  };

  handleUpdateField = (value, field) => {
    const { setPassword } = this.props;
    let updatedSetPassword = Object.assign({}, setPassword);
    updatedSetPassword[field] = value;

    this.props.updateSetPasswordLocal(updatedSetPassword);
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    let { setPassword } = this.props;
    this.props.onErrors([]);
    this.props.clearErrors();

    let errors = validation.validate(setPassword, true);
    let generalErrors = [];
    if (errors.length > 0) {
      generalErrors.push({ text: "Please correct the issues listed below." });
      // this.props.onErrors(errors);
      for (const [key, error] of Object.entries(errors)) {
        if (error.field) {
          this.props.onError(error.field, error.text);
        } else {
          generalErrors.push(error);
        }
      }

      this.props.onErrors(generalErrors);
      return;
    }

    this.props.createPassword(
      setPassword,
      getUrlVars(window.location.href)["reset_password_token"]
    );
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Edit));
