import React from "react";
import cn from "classnames";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from "react-redux";

import { NEW_ERRORS } from "patient_app/constants/actionTypes";

import Nav from "patient_app/components/navbar/Nav";

import dashboardBlockStyles from "patient_app/stylesheets/appointments/dashboardBlockStyles";
import communityTabStyles from "patient_app/stylesheets/communityTab/communityTabStyles";
import bookingStyles from "patient_app/stylesheets/curriculums/bookingStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    onErrors: (errors) => dispatch({ type: NEW_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {};
};

class CommunityTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
    };
  }
  componentDidMount = () => {
    let host = "https://app.workithealth.com";
    if (
      window.location.hostname &&
      window.location.hostname.startsWith("staging")
    ) {
      host = "https://staging.workithealth.com";
    } else if (
      window.location.hostname &&
      window.location.hostname.startsWith("dev")
    ) {
      host = "https://dev.workithealth.com";
    } else if (
      window.location.hostname &&
      window.location.hostname.startsWith("demophi")
    ) {
      host = "https://demophi.workithealth.com";
    } else if (
      (window.location.hostname &&
        window.location.hostname.startsWith("local")) ||
      (window.location.hostname && window.location.hostname.includes("ngrok"))
    ) {
      host = `http://localhost:3000`;
    }

    this.setState({ url: host });
  };

  componentDidUpdate = async (prevProps) => {};

  render() {
    let title = "Scheduler";

    return (
      <div className="Patient-App-Container">
        {/* {user && ( */}
        <div>
          <Nav />
          <div
            id="App-Body-Content"
            className={cn("App-Community-Show")}
            role="main"
          >
            <div>
              <div className="Tabs-Community">
                <div className="header">
                  <h2>{title}</h2>

                  <h1>
                    Please download the Workit Health mobile app to schedule or
                    re-schedule your appointment.
                  </h1>
                </div>
                <p>
                  Our self-scheduling feature works best with the Workit Health
                  mobile app on your smartphone. It is not fully available in
                  the web app used on computers.
                </p>
                <p>
                  <a href="https://itunes.apple.com/us/app/id1383957273">
                    Download the app on iOS
                  </a>{" "}
                  or{" "}
                  <a href="https://play.google.com/store/apps/details?id=com.workithealth.workitapp">
                    download the app on Android
                  </a>{" "}
                  and click "Schedule next Shared Medical Appointment" at the
                  bottom of your Action Items to browse available Shared Medical
                  Appointments and schedule one for yourself.
                </p>
                <p>
                  <b>
                    If you need to schedule or re-schedule your appointment but
                    are unable to download the app,{" "}
                    <a href={`${this.state.url}/admin_chat`}>
                      message your Admin Team
                    </a>
                    . They can help you change an existing appointment or
                    schedule a new one.
                  </b>
                </p>

                <p />
              </div>
            </div>
          </div>
        </div>

        <style jsx>{dashboardBlockStyles}</style>
        <style jsx>{communityTabStyles}</style>
        <style jsx>{bookingStyles}</style>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityTab);
