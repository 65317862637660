import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

import {
  PAGE_LOAD,
  ONBOARDING_PAGE_LOAD,
  PROFILE_MAIN_TAB,
  PROFILE_UPDATE_STATE_LOCAL,
} from "patient_app/constants/actionTypes";
import { fetchUser, fetchProfile } from "patient_app/api/commonActions";
import { fetchPaymentInitial } from "patient_app/api/profileActions";

import AccountSidebar from "patient_app/views/profiles/partials/AccountSidebar";
import Nav from "patient_app/components/navbar/Nav";
import Errors from "patient_app/components/utils/Errors";
import PatientChat from "patient_app/components/chat/PatientChat";

// React.lazy() causes flashing when switching between tabs
import Account from "patient_app/views/profiles/Account";
import DriversLicense from "patient_app/views/profiles/DriversLicense";
import Program from "patient_app/views/profiles/Program";
import DrugTestOrders from "patient_app/views/profiles/DrugTestOrders";
import Documents from "patient_app/views/profiles/Documents";
import Payment from "patient_app/views/profiles/Payment";
import Insurance from "patient_app/views/profiles/Insurance";
import Notifications from "patient_app/views/profiles/Notifications";
import Feedback from "patient_app/views/profiles/Feedback";

import permissions from "patient_app/helpers/permissions";
import { getUrlVars } from "components/utils/helpers/linkHelpers";
import { mobileCheck } from "patient_app/helpers/supported";

import layout from "patient_app/stylesheets/profiles/layout";

const mapDispatchToProps = (dispatch) => {
  return {
    onPageLoad: () => dispatch({ type: PAGE_LOAD }),
    fetchUser: () => dispatch(fetchUser()),
    fetchProfile: (user) => dispatch(fetchProfile(user)),
    fetchPaymentInitial: (profId) => dispatch(fetchPaymentInitial(profId)),
    setMainTab: (path) => dispatch({ type: PROFILE_MAIN_TAB, path: path }),
    setProfileState: (obj, value) =>
      dispatch({ type: PROFILE_UPDATE_STATE_LOCAL, obj: obj, value: value }),
  };
};

const mapStateToProps = (state) => {
  return {
    mainTab: state.profile.mainTab,
    subTab: state.profile.subTab,
    user: state.common.user,

    // filter through these
    profile: state.common.profile,
    hasPayment: state.profile.hasPayment,
    errors: state.common.errors,
    success: state.common.success,
    successText: state.common.successText,
    isFetching: state.common.isFetching,
    initialPaymentLoading: state.profile.initialPaymentLoading,
  };
};

class AccountRouter extends React.Component {
  componentDidMount = () => {
    document.title = "Account | Workit Health";
    this.props.fetchUser();
    !this.props.profile && this.props.fetchProfile();

    if (
      !mobileCheck() &&
      this.props.location.pathname === `/profiles/${this.props.match.params.id}`
    ) {
      // nav link to profile only goes to /profiles/:id
      // so if on web redirect to /profiles/:id/account
      this.props.history.push(
        `/profiles/${this.props.match.params.id}/account`
      );
      this.props.setMainTab(`/profiles/${this.props.match.params.id}/account`);
    } else {
      this.props.setMainTab(this.props.location.pathname);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.match.isExact && this.props.match.isExact) {
      if (mobileCheck() && this.props.mainTab.length) {
        this.props.setProfileState("mainTab", "");
        this.props.setProfileState("subTab", []);
      } else if (!mobileCheck()) {
        this.props.history.push(
          `/profiles/${this.props.match.params.id}/account`
        );
        this.props.setMainTab(
          `/profiles/${this.props.match.params.id}/account`
        );
      }
    }
  };

  render() {
    let {
      user,
      errors,
      csrf,
      hasPayment,
      isFetching,
      mainTab,
      success,
      successText,
    } = this.props;

    const profId = this.props.match.params.id;

    return (
      <div className="Patient-App-Container">
        <Nav />

        <div id="App-Body-Content" className="App-Profile">
          {user && (
            <PatientChat
              coachName={"Counselor"}
              userIsClinic={permissions.isClinic(user)}
              cta={permissions.isCore(user)}
              chatSessionId={
                getUrlVars(window.location.href)["chat_session_id"]
              }
            />
          )}

          <div className="profile-container">
            {(mainTab.length === 0 || !mobileCheck()) && <AccountSidebar />}

            <div
              id="profile-content-main"
              className={`${mainTab.length > 0 ? "" : "hidden"}`}
            >
              <Route path={`/profiles/:id/account`} component={Account} />
              <Route
                path={`/profiles/:id/drivers_license`}
                component={DriversLicense}
              />
              <Route path={`/profiles/:id/program`} component={Program} />
              <Route
                path={`/profiles/:id/drug_test_orders`}
                component={DrugTestOrders}
              />
              <Route path={`/profiles/:id/documents`} component={Documents} />
              <Route path={`/profiles/:id/payment`} component={Payment} />
              <Route path={`/profiles/:id/insurance`} component={Insurance} />
              <Route
                path={`/profiles/:id/notifications`}
                component={Notifications}
              />
              <Route path={`/profiles/:id/feedback`} component={Feedback} />
            </div>
          </div>
        </div>

        <style jsx>{layout}</style>
      </div>
    );
  }

  handleErrors = (errors) => {
    this.setState({ errors: errors });
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountRouter)
);
