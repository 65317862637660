import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import {
  BrowserRouter as Router,
  Route,
  NavLink,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import { fetchSpecialDelivery } from "patient_app/api/curriculumActions";

import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import Cluster from "patient_app/views/curriculums/partials/Cluster";

import { getUrlVars } from "components/utils/helpers/linkHelpers";
import permissions from "patient_app/helpers/permissions";
import assets from "patient_app/assets";
import curriculumHelper from "patient_app/helpers/curriculumHelper";

import courseStyles from "patient_app/stylesheets/curriculums/courseStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSpecialDelivery: (userId) => dispatch(fetchSpecialDelivery(userId)),
    onErrors: (errors) => dispatch({ type: NEW_ERRORS, errors: errors }),
    updateSelectedItem: (selectedItem) =>
      dispatch({
        type: CURRICULUM_UPDATE_SELECTED_ITEM,
        selectedItem: selectedItem,
      }),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.common.user,
    profile: state.common.profile,
    errors: state.common.errors,
    success: state.common.success,
    isFetching: state.common.isFetching,
    specialDelivery: state.curriculum.specialDelivery,
  };
};

class SpecialDelivery extends React.Component {
  componentDidMount = () => {
    const userId = getUrlVars(window.location.href)["user_id"];
    this.props.fetchSpecialDelivery(userId);
  };

  render() {
    let { specialDelivery } = this.props;

    let cluster =
      specialDelivery && curriculumHelper.clusterItems(specialDelivery);
    const userIdFromUrl = getUrlVars(window.location.href)["user_id"];
    return (
      <div className="section">
        {!cluster && <LoadingSpinner />}

        {cluster && cluster.length > 0 && (
          <Cluster cluster={cluster[0]} sectionLocked={false} />
        )}

        {cluster && cluster.length === 0 && (
          <div className="no-courses">
            <p>You do not have any pushed courses.</p>
          </div>
        )}

        <style jsx>{courseStyles}</style>
        {/* disable buttons if admin is viewing */}
        <style global jsx>{`
          #App-Body-Content button,
          #App-Body-Content a {
            pointer-events: ${!!userIdFromUrl ? "none" : ""};
          }
        `}</style>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialDelivery);
