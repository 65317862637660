import {
  SESSION_SET_LOCAL_STATE,
  SESSION_USERID_UPDATE,
  SESSION_EMAIL_UPDATE,
  SESSION_PASSWORD_UPDATE,
  SESSION_TOGGLE_SHOW_SIGNUP,
  UPDATE_SET_PASSWORD,
  SESSION_QUESTIONNAIRE_DUE,
  SESSION_HAS_PENDING_ITEM,
  SESSION_LOAD_OTP_CONFIG,
  UPDATE_MFA_CODE,
  SESSION_LOAD_CHECKLIST,
  SESSION_VERIFY_EMAIL,
  SESSION_ACTIVATE_CODE,
  SESSION_ACTIVATION_PAGE_LOAD,
  SESSION_UNIQUE_PHONE_UPDATE,
} from "patient_app/constants/actionTypes";

const initialState = {
  userId: "",
  email: "",
  uniquePhone: "",
  password: "",
  activateCode: "",
  showSignUpAction: false,
  setPassword: { password: "", password_conf: "" },
  mfaCode: "",
  checklistItemsAll: [],
  checklistItemsMissing: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SESSION_ACTIVATION_PAGE_LOAD:
      return {
        ...state,
        sessionKey: null,
        activateCode: "",
        password: "",
        email: "",
        uniquePhone: "",
        mfaCode: "",
        setPassword: { password: "", password_conf: "" },
        confirmationType: null,
        phone: null,
      };
    case SESSION_SET_LOCAL_STATE:
      return {
        ...state,
        [action.obj]: action.value,
      };
    case SESSION_USERID_UPDATE:
      return {
        ...state,
        userId: action.userId,
      };
    case SESSION_EMAIL_UPDATE:
      return {
        ...state,
        email: action.email,
      };
    case SESSION_UNIQUE_PHONE_UPDATE:
      return {
        ...state,
        uniquePhone: action.uniquePhone,
      };
    case SESSION_PASSWORD_UPDATE:
      return {
        ...state,
        password: action.password,
      };
    case SESSION_TOGGLE_SHOW_SIGNUP:
      return {
        ...state,
        showSignUpAction: action.showSignUpAction,
      };
    case UPDATE_SET_PASSWORD:
      return {
        ...state,
        setPassword: action.setPassword,
      };
    case SESSION_QUESTIONNAIRE_DUE:
      return {
        ...state,
        gutCheckDue: action.gutCheckDue,
        alcoholCheckDue: action.alcoholCheckDue,
        npsDue: action.npsDue,
      };
    case SESSION_HAS_PENDING_ITEM:
      return {
        ...state,
        hasPendingItem: action.hasPendingItem,
        path: action.path,
      };
    case SESSION_LOAD_OTP_CONFIG:
      return {
        ...state,
        provisioningUri: action.provisioningUri,
        otpSecretKey: action.otpSecretKey,
      };
    case UPDATE_MFA_CODE:
      return {
        ...state,
        mfaCode: action.code,
      };
    case SESSION_LOAD_CHECKLIST:
      return {
        ...state,
        checklistItemsAll: action.checklistItemsAll,
        checklistItemsMissing: action.checklistItemsMissing,
      };
    case SESSION_VERIFY_EMAIL:
      return {
        ...state,
        sessionKey: action.sessionKey,
        confirmationType: action.confirmationType,
        phone: action.phone,
        email: action.email,
        uniquePhone: action.uniquePhone,
      };
    case SESSION_ACTIVATE_CODE:
      return {
        ...state,
        activateCode: action.code,
      };
    default:
      return state;
  }
};
