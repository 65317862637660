import React, { Component } from "react";
//import cn from 'classnames';
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import VideoTrack from "patient_app/components/video/VideoTrack";
import _ from "lodash";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    isConnecting: state.videoCall.isConnecting,
    error: state.videoCall.error,
    participants: state.videoCall.participants,
    participantSids: state.videoCall.participantSids,
    room: state.videoCall.room,
    remoteTrackSids: state.videoCall.remoteTrackSids,
    remoteTracks: state.videoCall.remoteTracks,
  };
};

class RemoteVideo extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentDidUpdate = (prevProps) => {
    const oldRemoteAudioTracks = this.filterAudioTracks(
      prevProps.remoteTrackSids,
      prevProps.remoteTracks
    );
    const remoteAudioTracks = this.filterAudioTracks(
      this.props.remoteTrackSids,
      this.props.remoteTracks
    );

    if (!_.isEqual(oldRemoteAudioTracks, remoteAudioTracks)) {
      remoteAudioTracks.forEach((track) => {
        if (
          !Array.from(this.wrapperRef.getElementsByTagName("audio")).find(
            (mediaElement) => mediaElement.dataset.sid === track.sid
          )
        ) {
          const mediaElement = track.attach();
          mediaElement.dataset.sid = track.sid;
          this.wrapperRef.appendChild(mediaElement);
        }
      });

      oldRemoteAudioTracks.forEach((track) => {
        if (
          !remoteAudioTracks.find(
            (remoteAudioTrack) => remoteAudioTrack.sid === track.sid
          )
        ) {
          const mediaElements = track.detach();
          mediaElements.forEach((mediaElement) => mediaElement.remove());
        }
      });
    }
  };

  filterAudioTracks(sids, tracks) {
    return sids
      .map((sid) => tracks[sid])
      .filter((track) => {
        return track && track.kind === "audio";
      });
  }

  render() {
    let { room, isConnecting } = this.props;

    const remoteVideoTrack = this.getRemoteVideoTrack();

    return (
      <div className="remote-video-container">
        {room && !isConnecting && remoteVideoTrack && (
          <VideoTrack track={remoteVideoTrack} setRemoteVideo={true} />
        )}

        <div id="audio-tracks" ref={this.setWrapperRef}></div>
      </div>
    );
  }

  getRemoteVideoTrack = () => {
    let { remoteTrackSids, remoteTracks } = this.props;

    return remoteTrackSids
      .map((sid) => remoteTracks[sid])
      .filter((track) => {
        return track && track.kind === "video";
      })[0];
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RemoteVideo)
);
