import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { connect } from "react-redux";

import layout from "patient_app/stylesheets/profiles/layout_deprecated";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    fieldSpecificErrors: state.common.fieldSpecificErrors,
  };
};

class CheckboxField extends React.Component {
  render() {
    let {
      field,
      title,
      value,
      id,
      required,
      fieldSpecificErrors,
      uid,
    } = this.props;

    let error = fieldSpecificErrors[uid];

    return (
      <div className={cn("field checkbox", field)}>
        <div className="input-row">
          <input
            type="checkbox"
            checked={value}
            onChange={(e) => this.props.onUpdateField(e.target.checked, field)}
            aria-describedby={error && `aria-describedby-${uid}`}
            aria-invalid={error ? true : false}
            id={id}
            className={cn(error && "has-error")}
          />
          <label htmlFor={id}>
            {title} {required && <span className="light">(Required)</span>}
          </label>
        </div>

        {error && (
          <p
            role="alert"
            id={`aria-describedby-${uid}`}
            className={cn("field-specific-error", field)}
          >
            Error: {error}
          </p>
        )}

        <style jsx>{layout}</style>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxField);
