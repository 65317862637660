import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import _ from "lodash";
import { DateTime } from "luxon";

import MainLayout from "patient_app/components/layouts/MainLayout";
import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import TextField from "patient_app/components/input_fields/TextField";
import CheckboxField from "patient_app/components/input_fields/CheckboxField";
import SubmitButton from "patient_app/components/buttons/SubmitButton";
import FormErrors from "patient_app/components/utils/FormErrors";
import OnboardingProgressBar from "patient_app/components/utils/OnboardingProgressBar";
import ContactUs from "patient_app/views/onboarding/partials/ContactUs";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  Link,
} from "react-router-dom";
import { connect } from "react-redux";
import {
  PENDING_ITEM_TOGGLE_SIGNED,
  PENDING_ITEM_UPDATE_SIGNATURE,
  NEW_FORM_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";
import {
  fetchUser,
  fetchProfile,
  updateProfile,
} from "patient_app/api/commonActions";
import {
  fetchPendingItem,
  createConsentToTreatment,
} from "patient_app/api/pendingItemActions";

import { getChecklistItems } from "patient_app/api/sessionActions";
import {
  fetchMissionMap,
  fetchDocuments,
} from "patient_app/api/profileActions";

import { getUrlVars } from "patient_app/helpers/linkHelpers";
import { mobileCheck } from "patient_app/helpers/supported";
import permissions from "patient_app/helpers/permissions";

import assets from "patient_app/assets";
import layout from "patient_app/stylesheets/onboarding/layout";
import checklistStyles from "patient_app/stylesheets/users/checklistStyles";
import trackerActions from "patient_app/helpers/trackerActions";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(fetchUser()),
    fetchProfile: (user) => dispatch(fetchProfile(user)),
    fetchMissionMap: (userId) => dispatch(fetchMissionMap(userId)),
    getChecklistItems: () => dispatch(getChecklistItems()),
    fetchDocuments: (profileId, params) =>
      dispatch(fetchDocuments(profileId, params)),
    fetchPendingItem: (userId, title) =>
      dispatch(fetchPendingItem(userId, title)),
    toggleUserSigned: () => dispatch({ type: PENDING_ITEM_TOGGLE_SIGNED }),
    updateSignature: (signature) =>
      dispatch({ type: PENDING_ITEM_UPDATE_SIGNATURE, signature: signature }),
    onError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    onGeneralErrors: (errors) =>
      dispatch({ type: NEW_FORM_ERRORS, errors: errors }),
    clearErrors: () => dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    signPendingItem: (itemId, signature) =>
      dispatch(signPendingItem(itemId, signature)),
    declinePendingItem: (itemId) => dispatch(declinePendingItem(itemId)),
    createConsentToTreatment: (items, signature) =>
      dispatch(createConsentToTreatment(items, signature)),
    updateProfile: (updatedProfile) =>
      dispatch(updateProfile(updatedProfile, false)),
  };
};

const mapStateToProps = (state) => {
  return {
    checklistItemsAll: state.session.checklistItemsAll,
    checklistItemsMissing: state.session.checklistItemsMissing,
    clinicFiles: state.profile.clinicFiles,
    errors: state.common.formErrors,
    fieldSpecificErrors: state.common.fieldSpecificErrors,
    item: state.pendingItem.item,
    loading: state.pendingItem.loading,
    missionMap: state.profile.missionMap,
    profile: state.common.profile,
    signature: state.pendingItem.signature,
    success: state.pendingItem.success,
    user: state.common.user,
    userSigned: state.pendingItem.userSigned,
  };
};

class NewConsentToTreatment extends React.Component {
  componentDidMount = () => {
    document.title = "Consent and Legal | Workit Health";
    trackerActions.logMixpanelEvent("Consent", { onboarding: "web" });
    if (!this.props.user) {
      this.props.fetchUser();
    }

    if (!this.props.profile) {
      this.props.fetchProfile({ id: this.userId });
    }

    this.props.getChecklistItems();
  };

  componentDidUpdate = (prevProps) => {
    const currentSuccess = !prevProps.success && this.props.success;
    const previousSubmit = this.props.checklistItems === false;
    if (currentSuccess || previousSubmit) {
      if (this.props.user) {
        let link = "/onboarding/conclusion";
        if (this.props.user.tier !== 1) {
          link = "/onboarding/schedule";
        }

        if (getUrlVars(window.location.href)["redirect"] === "dashboard") {
          // user has already attended an appt, just needed to re-sign something
          // needs a page refresh to avoid getting bumped back into onboarding
          window.location.href = "/appointments";
        } else {
          this.props.history.push(link);
        }
      } else {
        this.props.history.push("/courses");
      }
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      consentToTreatment: {},
      agreedToTerms: false,
    };
  }

  render() {
    let {
      checklistItemsAll,
      checklistItemsMissing,
      errors,
      fieldSpecificErrors,
      item,
      loading,
      profile,
      signature,
      userSigned,
      user,
    } = this.props;

    let { consentToTreatment, agreedToTerms } = this.state;
    const termsLink = "https://www.workithealth.com/terms-of-service";
    const privacyLink = "https://www.workithealth.com/privacy-policy";
    return (
      <div className="Patient-App-Container Checklist">
        <MainLayout>
          <ContentMain halfBleed={true}>
            {checklistItemsAll && (
              <ContentBox className="onboarding">
                <OnboardingProgressBar />
                <ContactUs />
                <div className="inputs">
                  <h1>Consent and Legal</h1>

                  <p className="description">
                    Just like at a regular clinic visit, we ask that you review
                    and sign some standard paperwork prior to scheduling your
                    appointment.
                  </p>
                  <p className="description">
                    <span>
                      Please check the boxes and sign below to indicate that you
                      have reviewed and understood the documents.
                    </span>
                  </p>

                  <FormErrors />

                  {checklistItemsAll && (
                    <ul className="document-list">{this.renderItems()}</ul>
                  )}

                  <TextField
                    field="patient_signature"
                    title="First and Last Name"
                    value={consentToTreatment.patient_signature || ""}
                    id="patient_signature"
                    onUpdateField={this.handleUpdateField}
                    uid="patient_signature"
                    required={true}
                  />

                  <div className="consent-checkbox">
                    <CheckboxField
                      field="electronic_confirmation"
                      title="I agree to electronically sign this form."
                      value={
                        consentToTreatment.electronic_confirmation || false
                      }
                      onUpdateField={this.handleUpdateField}
                      id="electronic_confirmation"
                      uid="electronic_confirmation"
                      required={true}
                    />
                  </div>

                  {user && profile && profile.terms_agreed_to_at === null && (
                    <div className="consent-checkbox">
                      <CheckboxField
                        field="agreedToTerms"
                        fieldType="checkbox"
                        required={true}
                        value={agreedToTerms}
                        title={
                          <span className="light">
                            I agree to Workit's{" "}
                            <a href={termsLink} target="_blank">
                              terms of service
                            </a>{" "}
                            and{" "}
                            <a href={privacyLink} target="_blank">
                              privacy policy
                            </a>
                            .
                          </span>
                        }
                        onUpdateField={this.handleUpdateField}
                      />
                    </div>
                  )}
                  <SubmitButton
                    id="consent-to-treatment-agree"
                    text="I Agree"
                    loading={loading}
                    onSubmit={(e) => this.handleSubmit(e)}
                    classNames="full-width"
                  />
                </div>
              </ContentBox>
            )}
          </ContentMain>
          <style jsx>{layout}</style>
          <style jsx>{checklistStyles}</style>
        </MainLayout>
      </div>
    );
  }

  renderItems = () => {
    const { checklistItemsAll, checklistItemsMissing } = this.props;
    const { consentToTreatment } = this.state;
    const missingUids = _.map(checklistItemsMissing, "uid");

    return checklistItemsAll
      ?.filter((item) => item.model === "ClinicFile")
      .map((item, x) => {
        const error = this.props.fieldSpecificErrors[item.id];
        const missing = missingUids.includes(item.uid);
        let url = "#";
        if (missing) {
          url = checklistItemsMissing.find((ci) => ci.uid === item.uid)?.url;
        }

        return (
          <li className="block-item" key={x}>
            <input
              type="checkbox"
              checked={missing ? consentToTreatment[item.uid] || false : true}
              onChange={(e) => {
                if (missing) {
                  this.handleUpdateField(e.target.checked, item.uid);
                }
              }}
              aria-describedby={error && `aria-describedby-${item.uid}`}
              aria-invalid={error ? true : false}
              id={item.uid}
              className={error ? "has-error" : ""}
            />
            <label htmlFor={item.uid}>
              {item.title} <span className="light">(Required)</span>
            </label>

            {missing && (
              <a href={url} target="_blank">
                <img src={assets.chevronRightNavy} />
              </a>
            )}
          </li>
        );
      });
  };

  fetchUrl = (item) => {
    let { clinicFiles } = this.props;
    if (!item.clinic_file_id || !clinicFiles) {
      return item.file;
    }

    const cf = clinicFiles.find((file, x) => file.id === item.clinic_file_id);

    if (cf) {
      return cf.file.url;
    }
  };

  handleUpdateField = (value, field) => {
    if (field === "agreedToTerms") {
      this.setState({ agreedToTerms: value });
    } else {
      this.setState({
        consentToTreatment: {
          ...this.state.consentToTreatment,
          [field]: value,
        },
      });
    }
  };

  handleSubmit = (e) => {
    let {
      checklistItemsMissing,
      signature,
      fieldSpecificErrors,
      user,
      profile,
    } = this.props;
    let { consentToTreatment, agreedToTerms } = this.state;
    let errors = [];
    if (e) {
      e.preventDefault();
    }

    this.props.clearErrors();
    this.props.onGeneralErrors([]);
    let hasErrors = false;

    if (checklistItemsMissing) {
      checklistItemsMissing
        .filter((item) => item.model === "ClinicFile")
        .map((item) => {
          if (!consentToTreatment[item.uid]) {
            console.log(item.uid);
            hasErrors = true;
          }
        });
    }

    if (hasErrors) {
      errors.push({ text: "Please consent to all of the required documents." });
    }

    if (!consentToTreatment.electronic_confirmation) {
      errors.push({
        text: "Please agree to electronically sign this document.",
      });
    }

    if (
      !consentToTreatment.patient_signature ||
      consentToTreatment.patient_signature.length < 3 ||
      consentToTreatment.patient_signature.length > 51 ||
      consentToTreatment.patient_signature.trim().length === 0
    ) {
      errors.push({
        text: "Please sign your full name.",
      });
    }

    if (profile && profile.terms_agreed_to_at === null) {
      if (!agreedToTerms) {
        errors.push({
          text: "Please agree to the terms & conditions.",
        });
      } else {
        this.props.onError("agreedToTerms", undefined);
      }
    }

    if (errors.length > 0) {
      this.props.onGeneralErrors(errors);
      return;
    }

    let updatedProfile = Object.assign({}, profile);
    updatedProfile.terms_agreed_to_at = DateTime.now().toISO();
    this.props.updateProfile(updatedProfile);

    this.props.createConsentToTreatment(
      checklistItemsMissing || [],
      consentToTreatment.patient_signature
    );
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewConsentToTreatment)
);
