import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

class ContentMain extends React.Component {
  static defaultProps = {
    halfBleed: false,
    centerVertically: false,
  };

  render() {
    let { halfBleed, centerVertically, className } = this.props;

    return (
      <div
        id="content-main-container"
        role="main"
        className={cn(
          "content-main",
          halfBleed && "half-bleed",
          centerVertically && "center-vertically",
          className && className
        )}
      >
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentMain)
);
