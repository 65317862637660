import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import {
  USER_UPDATE_LOCAL,
  PROFILE_UPDATE_LOCAL,
  // NEW_ERRORS,
  NEW_FORM_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import FormErrors from "patient_app/components/utils/FormErrors";

import {
  fetchUser,
  updateUser,
  fetchProfile,
} from "patient_app/api/commonActions";
import { getUrlVars } from "components/utils/helpers/linkHelpers";

import onboardingAssets from "patient_app/assets/onboarding";

import MainLayout from "patient_app/components/layouts/MainLayout";

import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import Errors from "patient_app/components/utils/Errors";
import TextField from "patient_app/components/input_fields/TextField";
import ZipCodeField from "patient_app/components/input_fields/ZipCodeField";
import YesNo from "patient_app/components/buttons/YesNo";
import SubmitButton from "patient_app/components/buttons/SubmitButton";

import validation from "patient_app/helpers/validation";

import layout from "patient_app/stylesheets/onboarding/layout";

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch(updateUser(user)),
    fetchUser: () => dispatch(fetchUser()),
    fetchProfile: () => dispatch(fetchProfile()),
    onGeneralErrors: (errors) =>
      dispatch({ type: NEW_FORM_ERRORS, errors: errors }),
    updateUserLocal: (user) =>
      dispatch({ type: USER_UPDATE_LOCAL, user: user }),
    updateProfileLocal: (profile) =>
      dispatch({ type: PROFILE_UPDATE_LOCAL, profile: profile }),
    // onErrors: (errors) => dispatch({type: NEW_ERRORS, errors: errors}),
    onError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    clearErrors: () => dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.common.isFetching,
    success: state.common.success,
    errors: state.common.errors,
    user: state.common.user,
    profile: state.common.profile,
  };
};

class FinishSigningUp extends React.Component {
  componentDidMount = () => {
    let { user, profile } = this.props;
    if (!user) {
      this.props.fetchUser();
    }

    if (!profile) {
      this.props.fetchProfile();
    }

    document.title = "Sign Up | Workit Health";
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.success && this.props.success) {
      this.props.history.push(
        `/profiles/${this.props.profile.id}/demographics`
      );
    }
  };

  render() {
    let { loading, errors, user, profile } = this.props;

    let zipCode = profile && profile.zip_code ? profile.zip_code : "";

    const termsLink = "https://www.workithealth.com/terms-of-service";
    const privacyLink = "https://www.workithealth.com/privacy-policy";

    return (
      <MainLayout>
        <ContentMain halfBleed={true}>
          {user && (
            <ContentBox className="onboarding">
              <div className="inputs">
                <h1>Finish Signing Up</h1>

                <FormErrors />

                <div className="field">
                  <label>Email</label>
                  <p className="prefilled-email">{user.email}</p>
                </div>

                <TextField
                  field={"fir_name"}
                  title={"First Name"}
                  value={user.fir_name ? user.fir_name : ""}
                  id={"fir-name"}
                  onUpdateField={this.handleUpdateField}
                  onKeyPress={this.onKeyPress}
                  uid={"fir_name"}
                  required={true}
                />

                <TextField
                  field={"las_name"}
                  title={"Last Name"}
                  value={user.las_name ? user.las_name : ""}
                  id={"las-name"}
                  onUpdateField={this.handleUpdateField}
                  onKeyPress={this.onKeyPress}
                  uid={"las_name"}
                  required={true}
                />

                <ZipCodeField
                  field={"zip_code"}
                  title={"ZIP/Postal Code"}
                  value={zipCode}
                  id={"zip-code"}
                  onUpdateField={this.handleUpdateProfileField}
                  onKeyPress={this.onKeyPress}
                  uid={"zipCode"}
                  required={true}
                />

                <p className="terms-conditions">
                  By clicking submit, you agree to our{" "}
                  <a href={termsLink} target="_blank">
                    terms of service
                  </a>{" "}
                  and{" "}
                  <a href={privacyLink} target="_blank">
                    privacy policy
                  </a>
                  .
                </p>

                <SubmitButton
                  text={"Next"}
                  loading={loading}
                  onSubmit={this.handleSubmit}
                />
              </div>
            </ContentBox>
          )}
        </ContentMain>
        <style jsx>{layout}</style>
      </MainLayout>
    );
  }

  onKeyPress = (e) => {
    if (e.keyCode == 13) {
      this.handleSubmit();
    }
  };

  onKeyPressPassword = (e) => {
    if (e.keyCode == 13) {
      this.handleSubmit();
    } else {
      validation.validate(this.props.user);
    }
  };

  handleUpdateTier = () => {
    let updatedTier = this.props.user.tier === 4 ? 3 : 4;
    this.handleUpdateField(updatedTier, "tier");
  };

  handleUpdateField = (value, field) => {
    const { user } = this.props;
    let updatedUser = Object.assign({}, user);
    updatedUser[field] = value;

    this.props.updateUserLocal(updatedUser);
  };

  handleUpdateProfileField = (value, field) => {
    const { profile } = this.props;
    let updatedProfile = Object.assign({}, profile);
    updatedProfile[field] = value;

    this.props.updateProfileLocal(updatedProfile);
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    let { user, profile } = this.props;
    user = Object.assign({}, user);
    user.zip_code = profile.zip_code;
    this.props.clearErrors(); // clear old errors

    let errors = validation.validateFromOmniauth(user);
    if (errors.length > 0) {
      // this.props.onErrors(errors);
      for (const [key, error] of Object.entries(errors)) {
        this.props.onGeneralErrors([
          { text: "Please correct the issues listed below" },
        ]);
        this.props.onError(error.field, error.text);
      }
      window.scrollTo(0, 0);
      return;
    }
    this.props.updateUser(user);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FinishSigningUp)
);
